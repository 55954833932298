import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { Country } from 'src/app/model/country.model';

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

@Component({
  selector: 'app-admin-create-user',
  templateUrl: './admin-create-user.component.html',
  styleUrls: ['./admin-create-user.component.css']
})
export class AdminCreateUserComponent implements OnInit {
  allFields: string[]= [];
  profileForm: FormGroup;
  roles = null;
  countries: Country[];
  saved : boolean =false;
  send_status = false;

  constructor(private matcoService: MatcoService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.initForm();

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.roles = this.matcoService.getRoles();
  }

  private initForm() {
    this.profileForm = this.formBuilder.group({
      fName: new FormControl("", [Validators.required, Validators.minLength(2)]),
      lName: new FormControl("", [Validators.required, Validators.minLength(2)]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
      ]),
      userName: new FormControl(""),
      password: new FormControl('', [
        Validators.required, 
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      ]),
      password2: new FormControl(''),
      address: new FormControl("", [Validators.required, Validators.minLength(3)]),
      companyName: new FormControl("",[Validators.required, Validators.minLength(3)])
      ,
      postalCode: new FormControl("", [
        Validators.required
      //  Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),
      country: new FormControl('Singapore'),
      mobileNo: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),
      telNo: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),
      role: new FormControl("SUPERUSER"),
    });
  }

  createUser() {
    
    if(this.profileForm.invalid) {
      for (let i = 0; i < this.allFields.length; i ++) {
        if (this.profileForm.get(this.allFields[i]).invalid || this.profileForm.get(this.allFields[i]).value === "") {
          (<any>this.profileForm.get(this.allFields[i])).nativeElement.focus();
          break;
        }
      }
      return;
    }
    this.send_status = true;
    var token = localStorage.getItem('token');
    this.matcoService.createUser(this.profileForm.value,token)
    .subscribe(
      (data) => {
        this.profileForm.reset();
        this.send_status = false;
        window.scroll(0,200);
        this.saved = true;
        setTimeout(()=>this.router.navigate(['/admin-users']),2000);
      }
    )
    
  }

  get form() {
    return this.profileForm.controls;
  }

  isFieldRequired(field: string) {
    return this.profileForm.get(field).errors.required;
  }

  isFieldRequiredMinLength(field: string) {
    return this.profileForm.get(field).errors.minlength;
  }

  isFieldRequiredPattern(field: string) {
    return this.profileForm.get(field).errors.pattern;
  }

  isFieldvalid(field: string) {
    return this.profileForm.get(field).invalid && (this.profileForm.get(field).dirty || this.profileForm.get(field).touched)
  }

  getAllFields () {
    Object.keys(this.profileForm.controls).forEach((key) => {
      return this.allFields.push(key);
    });
  }
}
