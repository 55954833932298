import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private matcoService: MatcoService,
  ) { }
  

  ngOnInit() {
  }
  email = null;
   msg = null;
   message = "";
  
  sendNewsLetter(email:string){
    this.matcoService.sendNewsLetter(email).subscribe(data => {
      this.msg = data;
      this.message =  this.msg[0].Message;
      this.email =  '';
    }); 
  }

}
