import { Component, OnInit, } from '@angular/core';

import { Product } from 'src/app/model/product.model';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Category } from 'src/app/model/category.model';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products: Product[];
  categories: Category[];
  brand: string;
  category:string;
  snackBarMsg: string;
  userRole;

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.route.params
    .subscribe(
      (params: Params) => {
        this.brand = params['brand'];
      }
    );

     this.matcoService.getProductListByBrand(this.brand)
     .subscribe(
       (products: Product[]) => {
         this.products = products;
         window.scroll(0,0);
       }
     ); 
     
    this.matcoService.getCategoriesByBrand(this.brand)
      .subscribe(
        (categories: Category[]) => {
          this.categories = categories;
        }
      );

      this.matcoService.updateCartEmitter("addProduct");

      if(localStorage.getItem('user')){
        this.userRole = JSON.parse(localStorage.getItem('user')).role;
      }
    }
    
    transform(value: string, regex: string, replaceValue: string): any {
      return value.replace(regex, replaceValue);
    }
    
    showProductsByCategory(category: string, brand: string) {
      this.category = category;
      this.matcoService.getProductsByCategory(category, this.brand)
      .subscribe(
        (products: Product[]) => {

          this.products = products;
        }
      );
    }
    
    addProductToCart(product : Product){
     
     var currentproduct = JSON.parse(localStorage.getItem("productsInCart"));
     
    
      if(currentproduct[product.itemCode]){
        this.snackBarMsg = "Product already exists in inquiry";
      }else{
        this.snackBarMsg = "Product added to inquiry";
      }
      currentproduct[product.itemCode] = product;
      currentproduct[product.itemCode].quantity = 1;
      currentproduct[product.itemCode].remarks = '';
      
      localStorage.setItem("productsInCart", JSON.stringify(currentproduct));
      this.matcoService.updateCartEmitter("addProduct");
     
    }

    addProductToOrder(product : Product){
     
      var currentproductOrder = JSON.parse(localStorage.getItem("productsInOrder"));
      
     
       if(currentproductOrder[product.itemCode]){
         this.snackBarMsg = "Product already exists in cart";
       }else{
         this.snackBarMsg = "Product added to cart";
       }
       currentproductOrder[product.itemCode] = product;
       currentproductOrder[product.itemCode].price = product.price;
       currentproductOrder[product.itemCode].quantity = 1;
       currentproductOrder[product.itemCode].remarks = '';
       
       localStorage.setItem("productsInOrder", JSON.stringify(currentproductOrder));
       this.matcoService.updateCartEmitter("addProduct");
      
     }
}
