import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  users = [];

  constructor(private matcoService: MatcoService) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    var token = localStorage.getItem('token');
    this.matcoService.getAllUsers(token)
      .subscribe(
        (data) => {
          this.users.push(data);

          if(this.users) {
            this.users = this.users[0].filter((user) => {

              return user.role === 'SUPERUSER' || user.role === 'USER'})
          }

        }
      )
  }

}
