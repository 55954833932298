import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.css']
})
export class AdminOrdersComponent implements OnInit {

  constructor(private matcoService: MatcoService) { }

  searchText = "";
  orders = null;

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.matcoService.getAllOrdersAdmin().subscribe(data => {
      if(data) {
        // console.log(data[0].order)
        this.orders = data[0].order;
      }
    })
  }

}
