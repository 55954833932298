import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePassForm: FormGroup;
  email: string;
  token: string;
  changed: boolean = false;

  constructor(private matcoService: MatcoService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {

    this.initForm();
  }

  private initForm() {
    this.changePassForm = this.formBuilder.group({
      password: new FormControl('', [
        Validators.required, 
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      ]),
      password2: new FormControl(''),
    });
  }

  get form() {
    return this.changePassForm.controls;
  }

  isFieldRequired(field: string) {
    return this.changePassForm.get(field).errors.required;
  }

  isFieldRequiredMinLength(field: string) {
    return this.changePassForm.get(field).errors.minlength;
  }

  isFieldRequiredPattern(field: string) {
    return this.changePassForm.get(field).errors.pattern;
  }

  isFieldvalid(field: string) {
    return this.changePassForm.get(field).invalid && (this.changePassForm.get(field).dirty || this.changePassForm.get(field).touched)
  }

  changePassword() {
    this.email = JSON.parse(localStorage.getItem('user')).email;
    this.token = localStorage.getItem('token');
    this.matcoService.changePassword(this.email, this.token, this.changePassForm.value);
      // .subscribe(
      //   (data) => {
      //     console.log(data)
      //   }
      // );
    this.initForm();
    this.changed = true;
    this.matcoService.logout();
    setTimeout(()=>this.router.navigate(['/']),2000);
  }
}
