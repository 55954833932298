import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Country } from 'src/app/model/country.model';

@Component({
  selector: 'app-admin-manage-customer',
  templateUrl: './admin-manage-customer.component.html',
  styleUrls: ['./admin-manage-customer.component.css']
})
export class AdminManageCustomerComponent implements OnInit {
  profileForm: FormGroup;
  userNo: string;
  customer = null;
  roles = null;
  countries: Country[];
  saved : boolean =false;
  companies = null;
  phone_country_code;
  paymentTerms;
  priceList;
  send_status = '';

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.route.params
    .subscribe(
      (params: Params) => {
        this.userNo = params['email'];
      }
    );
    var token = localStorage.getItem('token');
    this.phone_country_code = this.matcoService.loadCountryCode();
    this.matcoService.getCustomerProfile(token,this.userNo)
      .subscribe(
        (data) => {
          this.customer = data;
          // console.log(this.customer)
          this.profileForm = this.formBuilder.group({
            fName: new FormControl(this.customer.fName, [Validators.required, Validators.minLength(2)]),
            lName: new FormControl(this.customer.lName, [Validators.required, Validators.minLength(2)]),
            email: new FormControl(this.customer.email, [
              Validators.required,
              Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
            ]),
            userName: new FormControl(this.customer.userName),
            address1: new FormControl(this.customer.address1),
            address2: new FormControl(this.customer.address2),
            companyName: new FormControl(this.customer.companyName),
            currency: new FormControl(this.customer.currency),
            postalCode: new FormControl(this.customer.postalCode, [
              Validators.required,
              Validators.pattern(/^[1-9]+[0-9]*$/)
            ]),
            phone_country_code: new FormControl(this.getCountryCode(this.customer.telNo, this.customer.telNoCode, this.customer.country)),
              cellphone_country_code: new FormControl(this.getCountryCode(this.customer.mobileNo, this.customer.mobileNoCode, this.customer.country)),
            country: new FormControl(this.customer.country),
            mobileNo: new FormControl(this.getNumber(this.customer.mobileNo, this.customer.mobileNoCode)),
              telNo: new FormControl(this.getNumber(this.customer.telNo, this.customer.telNoCode)),
            role: new FormControl(this.customer.role),
            paymentTerms: new FormControl(this.paymentTerms),
            priceList: new FormControl(),
            cardCode: new FormControl(this.customer.sapCardName+'-'+this.customer.sapCardCode),
          });

          this.matcoService.getAllCompanies(this.customer.companyName, this.customer.currency).subscribe( companies => {
            // console.log(companies);
            this.companies = companies;
            this.paymentTerms = companies[0].paymentTerms;
            this.priceList = companies[0].priceList;


            this.profileForm = this.formBuilder.group({
              fName: new FormControl(this.customer.fName, [Validators.required, Validators.minLength(2)]),
              lName: new FormControl(this.customer.lName, [Validators.required, Validators.minLength(2)]),
              email: new FormControl(this.customer.email, [
                Validators.required,
                Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
              ]),
              userName: new FormControl(this.customer.userName),
              address1: new FormControl(this.customer.address1),
              address2: new FormControl(this.customer.address2),
              companyName: new FormControl(this.customer.companyName),
              currency: new FormControl(this.customer.currency),
              postalCode: new FormControl(this.customer.postalCode, [
                Validators.required,
                Validators.pattern(/^[1-9]+[0-9]*$/)
              ]),
              phone_country_code: new FormControl(this.getCountryCode(this.customer.telNo, this.customer.telNoCode, this.customer.country)),
              cellphone_country_code: new FormControl(this.getCountryCode(this.customer.mobileNo, this.customer.mobileNoCode, this.customer.country)),
              country: new FormControl(this.customer.country),
              mobileNo: new FormControl(this.getNumber(this.customer.mobileNo, this.customer.mobileNoCode)),
              telNo: new FormControl(this.getNumber(this.customer.telNo, this.customer.telNoCode)),
              role: new FormControl(this.customer.role),
              paymentTerms: new FormControl(this.paymentTerms),
              priceList: new FormControl(this.priceList),
              cardCode: new FormControl(this.customer.sapCardName+'-'+this.customer.sapCardCode),
            });
          })

         
        }
      );

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.roles = this.matcoService.getRoles();

    //  setTimeout(()=>this.router.navigate(['/admin-customers']),2000);
  }


  saveProfile() {
    // console.log(this.profileForm.value)
    this.send_status = 'sending';
    this.matcoService.saveCustomerProfile(this.profileForm.value,this.userNo)
    .subscribe(
      (data) => {
        this.send_status = '';
        window.scroll(0,200);
        this.saved = true;
        setTimeout(()=>this.router.navigate(['/admin-customers']),2000);
      }
    )
    
  }

  getCountryCode(phone, code, countryProfile) {

    if(phone) {
      // console.log(code)
      return code;
    } else {
      this.matcoService.loadCountryCode().forEach(country => {
        if(countryProfile == country.name) {
          // console.log(country.code)
          return country.code;
        }
      })
    }
  }

  getNumber(phone, code) {
    if(phone) {
      // console.log(phone.substring(code.length,phone.length))
      return phone.substring(code.length,phone.length);
    } else {
      return '';
    }
  }
}
