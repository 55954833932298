import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.css']
})
export class EmailValidationComponent implements OnInit {

  validated: boolean =false;

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    const firstParam: string = this.route.snapshot.queryParamMap.get('Email');
    const secondParam: string = this.route.snapshot.queryParamMap.get('UserId');
    // console.log(firstParam)
    // console.log(secondParam)
    this.matcoService.emailValidation(firstParam,secondParam)
      .subscribe(
        (data) => {
          console.log(data);
        }
      )
  }

}
