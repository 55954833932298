import { Component, OnInit } from '@angular/core';
import { MatcoService } from '../../services/matco.service';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  brands = null;
  groups = null;
  banner = false;
  constructor(private mtco : MatcoService) { }

  ngOnInit() {
    
    this.banner = true;
    this.brands = null;
    this.mtco.getBrand().subscribe(
      data => { 
        this.brands = data;
        this.groups = this.groupings(this.brands);
        
      },
      error => { 
        // console.log(error) 
      }
    )
    this.mtco.updateCartEmitter("loadProduct");
  }

  transform(value: string, regex: string, replaceValue: string): any {
    return value.replace(regex, replaceValue);
  }

  ngAfterViewInit(): void {
    $.getScript('assets/js/custom.js');
    
  
  }


  groupings(brands){
    
    var groups = {};
    var bGroup = 0;
    for(var b of brands){
     
      if(bGroup != b.Group ){
        groups[b.Group] = {
          manufacturers :  []
        }
        bGroup = b.Group;
      }
      
      groups[b.Group].manufacturers.push(
          { 
            Group: b.Group,
            Manufacturer: b.Manufacturer,
            Logo: b.Logo,
            FirmCode : b.FirmCode
          }
        );
    }
    
    //console.log(groups[1]);
    return groups;
    
  }


  



}
