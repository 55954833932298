import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';
import { Profile } from 'src/app/model/profile.model';
import { Country } from 'src/app/model/country.model';
import { Currency } from 'src/app/model/currency.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  profile: Profile;
  countries: Country[];
  currencies: Currency[];
  snackBarMsg: string;
  saved: boolean = false;
  userNo : string;
  phone_country_code;

  constructor(private formBuilder: FormBuilder,
              private matcoService: MatcoService,
              private router: Router) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
    this.phone_country_code = this.matcoService.loadCountryCode();
    this.matcoService.getUserProfile(this.userNo)
    .subscribe(
      (profile: Profile) => {
        this.profile = profile;
        // console.log(this.profile)
        this.profileForm = this.formBuilder.group({
          fName: new FormControl(this.profile.fName, [Validators.required, Validators.minLength(2)]),
          lName: new FormControl(this.profile.lName, [Validators.required, Validators.minLength(2)]),
          email: new FormControl(this.profile.email, [
            Validators.required,
            Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
          ]),
          userName: new FormControl(this.profile.userName),
          address1: new FormControl(this.profile.address1),
          address2: new FormControl(this.profile.address2),
          companyName: new FormControl(this.profile.companyName),
          postalCode: new FormControl(this.profile.postalCode, [
            Validators.required,
            Validators.pattern(/^[1-9]+[0-9]*$/)
          ]),
          phone_country_code: new FormControl(this.getCountryCode(this.profile.telNo, this.profile.telNoCode, this.profile.country)),
          cellphone_country_code: new FormControl(this.getCountryCode(this.profile.mobileNo, this.profile.mobileNoCode, this.profile.country)),
          country: new FormControl(this.profile.country),
          mobileNo: new FormControl(this.getNumber(this.profile.mobileNo, this.profile.mobileNoCode)),
          telNo: new FormControl(this.getNumber(this.profile.telNo, this.profile.telNoCode)),
          currency: new FormControl(this.profile.currency),
        });
      }
    )

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.matcoService.getAllCurrencies()
      .subscribe(
        (currencies: Currency[]) => {
          this.currencies = currencies;
        }
      );
  }

  saveProfile() {
    let token = localStorage.getItem('token');
    this.matcoService.saveProfile(this.profileForm.value, this.userNo)
      .subscribe(
        (data) => {
          this.saved = true;
          window.scrollTo(0,0);
        }
      )
  }


  getCountryCode(phone, code, countryProfile) {

    if(phone) {
      // console.log(code)
      return code;
    } else {
      this.matcoService.loadCountryCode().forEach(country => {
        if(countryProfile == country.name) {
          // console.log(country.code)
          return country.code;
        }
      })
    }
  }

  getNumber(phone, code) {
    if(phone) {
      // console.log(phone.substring(code.length,phone.length))
      return phone.substring(code.length,phone.length);
    } else {
      return '';
    }
  }
}
