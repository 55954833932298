import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-manage-order',
  templateUrl: './admin-manage-order.component.html',
  styleUrls: ['./admin-manage-order.component.css']
})
export class AdminManageOrderComponent implements OnInit {

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute,
              private router: Router) { }


  orderId = null;
  orders = null;
  details = null;
  remarks = new FormControl('');
  validationDetails = new FormControl('');
  send_email_status = '';
  validate_status = '';
  add_status = '';
  errorMsg = "";
  docNum;
  userNo;
  salesorderErrorMsg = "";
  validateErrorMsg = "";

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.errorMsg ="";
    this.send_email_status = "";

    this.userNo = JSON.parse(localStorage.getItem('user')).userNo;

    this.route.params
    .subscribe(
      (params: Params) => {
        this.orderId = params['id'];
        this.matcoService.getOrderDetailsAdmin(this.orderId).subscribe(data => {
          // console.log(data[0].order)
          this.orders = data[0].order;
          
          this.orders[0].taxAmount = parseFloat(this.orders[0].taxAmount).toFixed(2);
          this.orders[0].discountAmount = parseFloat(this.orders[0].discountAmount).toFixed(2);
          this.orders[0].docTotal = parseFloat(this.orders[0].docTotal).toFixed(2);
          this.details = this.orders[0].details;
          this.details.forEach(element => {
            element.price = parseFloat(element.price).toFixed(2);
            element.lineTotal = parseFloat(element.lineTotal).toFixed(2);
          });
        });
      }
    );
  }


  download() {
      html2canvas(document.querySelector("#section")).then(canvas => {
      // console.log(canvas)
      var pdf = new jsPDF('p', 'pt', 'a4');

      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,0,0,canvas.width, canvas.height);
      pdf.save('converteddoc.pdf');

  });

  }

  resendEmail(docNum) {
    // console.log(docNum)
    this.send_email_status = "sending";
    // console.log(this.send_email_status)
    this.matcoService.resendEmail(docNum).subscribe(data => {
    //  console.log(data)
     this.send_email_status = "";
    }, error => {
      // console.log(error)
      this.errorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
      this.send_email_status = "";
    });
  }

  updateDetails(docNum) {
    this.validate_status = "validating";
    this.matcoService.updateValidationDetails(this.remarks, this.validationDetails,docNum, this.userNo).subscribe(data => {
        this.validate_status = "validated";
        // console.log(data);
        this.router.navigate(['/admin-orders']);
    }, error => {
      this.validate_status = "";
      this.validateErrorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
    });
  }

  createSalesOrder(docNum) {
    this.add_status = "adding"; 
    this.matcoService.addSalesOrder(docNum,this.userNo).subscribe(data => {
      this.add_status = "";
      // console.log(data)
      this.router.navigate(['/admin-orders']);
    },error => {
      this.add_status = "";
      this.salesorderErrorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
    })
  }
}
