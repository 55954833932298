import { Component, OnInit, AfterViewInit} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';

declare var Stripe: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit {

  allFields: string[]= [];
  paymentForm: FormGroup;

  elements: any;
  paymentRequest: any;
  prButton: any;
  results:any;
  cardElement:any;
  expElement:any;
  cvcElement:any;
  stripe:any;
  loading:any = "";
  submitted:boolean = false;

  constructor( 
              private matcoService: MatcoService,
              private formBuilder: FormBuilder) { }
  docTotal: string;
  orderNo: string;
  email : string;
  products:any;
  currency:any;
  userNo: any;
  code: String;
  mobileNo: String;
  fullNo: String;
  name: String;
  mobileCode: String;
  reservationFee: string;
  companyName: string;
  user:any;
  paymentType = new FormControl('Full');

  ngOnInit() {

    document.getElementById('loading').style.display = 'none';
    document.getElementById('success').style.display = 'none';
    this.user = JSON.parse(localStorage.getItem('user'));
    this.companyName = JSON.parse(localStorage.getItem('user')).companyName;
    this.email = JSON.parse(localStorage.getItem('user')).email;
    this.name = JSON.parse(localStorage.getItem('user')).displayUserName;
    this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
    this.mobileCode = JSON.parse(localStorage.getItem('user')).mobileNoCode;
    this.fullNo = JSON.parse(localStorage.getItem('user')).mobileNo;
    this.mobileNo = this.fullNo.substring( this.mobileCode.length, this.fullNo.length);
    this.code = this.fullNo.substring(0, this.mobileCode.length);

    this.matcoService.getUserOrderByEmail(this.email).subscribe(data => {
      // console.log(data[0].order[0])
      this.orderNo = data[0].order[0].docNum;
      this.currency = data[0].order[0].currency;
      this.docTotal = (parseFloat(data[0].order[0].docTotal)).toString().split(".")[0];
      this.products = data[0].order[0].details;
      this.reservationFee =  (parseFloat(data[0].order[0].docTotal) * 0.10).toString().split(".")[0];
    })

    this.paymentForm = this.formBuilder.group({
      name: new FormControl(this.name, [Validators.required, Validators.minLength(2)]),
      email: new FormControl(this.email, [
        Validators.required,
        Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
      ]),
      mobileNo: new FormControl(this.mobileNo),
      code: new FormControl(this.code),
      // address: new FormControl(''),
      // country: new FormControl('SG'),
      // city: new FormControl(''),
      // state: new FormControl(''),

      // postal_code: new FormControl(''),
    });

  }
  ngAfterViewInit() {
    // this.stripe = Stripe('pk_test_dp7tUH0hLCaOkHQ5AuBRuOeV00YWtRL4qz');
    this.stripe = Stripe('pk_live_kSXxNWauWunxMN6Ln2Bnv4zf006XPPTYmt');
    this.elements = this.stripe.elements();

    this.cardElement = this.elements.create('cardNumber');
    this.cardElement.mount('#card-element'); 

    this.expElement = this.elements.create('cardExpiry');
    this.expElement.mount('#exp-element');
    
    this.cvcElement = this.elements.create('cardCvc');
    this.cvcElement.mount('#cvc-element');
    // this.cardElement = this.elements.create('card',{
    //   hidePostalCode : true
    // });

    // this.cardElement.mount('#card-element');

  }

  pay1() {
    // console.log(this.paymentType.value)
    // console.log(this.reservationFee)
    if((<HTMLInputElement>document.getElementById('pType-f')).checked)
    {
      console.log((<HTMLInputElement>document.getElementById('pType-f')).value)
    } else {
      console.log((<HTMLInputElement>document.getElementById('pType-r')).value)
    }
  }
    
  pay() {
    document.getElementById('notLoading').style.display = 'none';

    document.getElementById('loading').style.display = 'inline';
    var matcoService = this.matcoService;

    // console.log(this.paymentForm.value)
    var userInfo = this.paymentForm.value;
    var userAddress = JSON.parse(localStorage.getItem('user'));

    this.loading = this.stripe.createPaymentMethod({
      type: 'card',

      card: this.cardElement,
      billing_details: { 
        name:  userInfo.name, 
        email:  userInfo.email,
        phone: userInfo.code+userInfo.mobileNo,
        address : {
          city : userInfo.city,
          country : userInfo.country,
          state: userInfo.state,
          line1 : userAddress.address1,
          line2 : userAddress.address2
        }
      }

  }).then(result =>  {
    document.getElementById('card-errors').innerText = "";
    if (result.error) {
          // console.log(result.error)
          document.getElementById('card-errors').innerText = "Invalid Request. Please check your billing and payment information fields.";
          document.getElementById('notLoading').style.display = 'inline';
          document.getElementById('loading').style.display = 'none';     
      } else {
        // console.log(result.paymentMethod.id )
       var paymentInfo = document.getElementById('card-button').getAttribute('name').split('-');
       var orderInfo = document.getElementById('order-company').getAttribute('name').split('>');
      //  console.log( orderInfo[0])
      //  console.log( orderInfo[1])
        matcoService.payment(result.paymentMethod.id, "", paymentInfo[0], paymentInfo[1], orderInfo[0], orderInfo[1]).subscribe(data => {
          // console.log("PASOK!!!")
          // console.log(data);
          handleServerResponse(data);
        },error => {
          // console.log(error)
          document.getElementById('card-errors').innerText = error.statusText + ". Please contact the administrator of Matco Asia." ;
          document.getElementById('notLoading').style.display = 'inline';
          document.getElementById('loading').style.display = 'none';  
        });
        // handleServerResponse(result);
      }

  });

  function handleServerResponse(response) {
    // console.log(response);

    // var stripe = Stripe('pk_test_dp7tUH0hLCaOkHQ5AuBRuOeV00YWtRL4qz');
    var stripe = Stripe('pk_live_kSXxNWauWunxMN6Ln2Bnv4zf006XPPTYmt');
    if (response.error) {
        // Show error from server on payment form
        var errorElement = document.getElementById('card-errors');
        // console.log(response.error);
        if(response.error != '') {
          errorElement.innerText = response.error;
          document.getElementById('notLoading').style.display = 'inline';
          document.getElementById('loading').style.display = 'none';    
        } else {
          errorElement.innerText = this.result.error.message;
        }
        

    } else if (response.requires_action) {
        // console.log("HANDLE!!!")
        // Use Stripe.js to handle required card action
        // console.log(response);
        // console.log(response.payment_intent_client_secret)

        const resultResponse = stripe.confirmCardPayment(
          response.payment_intent_client_secret,
          {

          }).then(function(result) {

            if(result.error) {
              // console.log(result.error.message);
              if(result.error.message != '') {
                document.getElementById('card-errors').innerText = result.error.message ;
                document.getElementById('notLoading').style.display = 'inline';
                document.getElementById('loading').style.display = 'none'; 
              } else {
                document.getElementById('card-errors').innerText = result.error.statusText + ". Please contact the administrator of Matco Asia." ;
                document.getElementById('notLoading').style.display = 'inline';
                document.getElementById('loading').style.display = 'none'; 
              }
              
            }else {
              result.paymentIntent.name = (<HTMLInputElement>document.getElementById('name-payment')).value;
              result.paymentIntent.email = (<HTMLInputElement>document.getElementById('email-add-payment')).value;
              result.paymentIntent.phone = (<HTMLInputElement>document.getElementById('mobileNo')).value;
              // result.paymentIntent.line1 = (<HTMLInputElement>document.getElementById('address')).value;
              // result.paymentIntent.line2 = (<HTMLInputElement>document.getElementById('city')).value + ' ' + (<HTMLInputElement>document.getElementById('state')).value;
              // result.paymentIntent.postalCode = (<HTMLInputElement>document.getElementById('postalCode')).value;
              // result.paymentIntent.country = (<HTMLInputElement>document.getElementById('country-country')).value;
              // console.log(name)
              // console.log(result);
              // console.log(result.paymentIntent.id)
              // console.log(result.paymentIntent.status)
              // console.log(result.paymentIntent.payment_method_types[0])
              // console.log(result.paymentIntent.created)
              var userInfo = document.getElementById('order-user').getAttribute('name').split('>');
              var pType = '';

              if((<HTMLInputElement>document.getElementById('pType-f')).checked)
              {
               pType = (<HTMLInputElement>document.getElementById('pType-f')).value;
              } else {
                pType=(<HTMLInputElement>document.getElementById('pType-r')).value;
              }

              matcoService.updatePayment(result,userInfo[0],userInfo[1],true,pType).subscribe(data =>{
                // console.log("INTENT WITH ACTION!!!")
                // console.log(data)
                review(data);
                localStorage.setItem("productsInOrder","{}")
                matcoService.updateCartEmitter("addProduct");
                document.getElementById('notLoading').style.display = 'inline';
                document.getElementById('loading').style.display = 'none';  
                document.getElementById('success').style.display = 'inline';
                document.getElementById('paymentReview').style.display = 'none'; 
                window.scroll(0,0);
              },error => {
                document.getElementById('card-errors').innerText = error.statusText + ". Please contact the administrator of Matco Asia." ;
                document.getElementById('notLoading').style.display = 'inline';
                document.getElementById('loading').style.display = 'none';    
                // console.log(error)
              });
            } 
          });

    } else {
        // console.log("SUCCESS MESSAGE!!!")
        // console.log(response)
        var userInfo = document.getElementById('order-user').getAttribute('name').split('>');
        var pType = '';

        if((<HTMLInputElement>document.getElementById('pType-f')).checked)
        {
          pType = (<HTMLInputElement>document.getElementById('pType-f')).value;
        } else {
          pType=(<HTMLInputElement>document.getElementById('pType-r')).value;
        }

        matcoService.updatePayment(response,userInfo[0],userInfo[1],false,pType).subscribe(data =>{
          review(data);
          // console.log("INTENT WITH ACTION!!!")
          // console.log(data)
          localStorage.setItem("productsInOrder","{}");
          localStorage.setItem("secret", "false");
          document.getElementById('notLoading').style.display = 'inline';
          document.getElementById('loading').style.display = 'none';  
          document.getElementById('success').style.display = 'inline';
          document.getElementById('paymentReview').style.display = 'none'; 
          matcoService.updateCartEmitter("addProduct");
          window.scroll(0,0);
        },error => {
          // console.log(error)
          document.getElementById('card-errors').innerText = error.statusText + ". Please contact the administrator of Matco Asia." ;
          document.getElementById('notLoading').style.display = 'inline';
          document.getElementById('loading').style.display = 'none';   
        });
    }

}

function review(data) {
  // console.log(data);
  (<HTMLInputElement>document.getElementById('name')).innerText = data.name;
  (<HTMLInputElement>document.getElementById('companyName')).innerText = data.companyName;
  (<HTMLInputElement>document.getElementById('email-add')).innerText = data.email;
  (<HTMLInputElement>document.getElementById('paymentTransNo')).innerText = data.paymentTransNo;
  (<HTMLInputElement>document.getElementById('paymentType')).innerText = data.paymentType;
  (<HTMLInputElement>document.getElementById('paymentDate')).innerText = data.createDate.split('T')[0];
  (<HTMLInputElement>document.getElementById('paymentStatus')).innerText = data.paymentStatus;
  (<HTMLInputElement>document.getElementById('paymentRemarks')).innerText = data.paymentRemarks;
  (<HTMLInputElement>document.getElementById('paymentDetails')).innerText = data.paymentDetails;
  (<HTMLInputElement>document.getElementById('docTotal')).innerText = data.docTotal;
  (<HTMLInputElement>document.getElementById('currency')).innerText = data.currency;
  (<HTMLInputElement>document.getElementById('shippingAddress')).innerText = data.shipToAddress1 + ' ' + data.shipToAddress2 + ' ' + data.shipToCountry + ' ' + data.shipToPostalCode;
  (<HTMLInputElement>document.getElementById('billingAddress')).innerText = data.shipToAddress1 + ' ' + data.shipToAddress2 + ' ' + data.shipToCountry + ' ' + data.shipToPostalCode;


}
  }
}
