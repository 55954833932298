import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Country } from 'src/app/model/country.model';

@Component({
  selector: 'app-admin-manage-user',
  templateUrl: './admin-manage-user.component.html',
  styleUrls: ['./admin-manage-user.component.css']
})
export class AdminManageUserComponent implements OnInit {
  profileForm: FormGroup;
  userNo: string;
  user = null;
  roles = null;
  countries: Country[];
  saved : boolean =false;
  companies = null;
  phone_country_code;
  send_status = '';

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.route.params
    .subscribe(
      (params: Params) => {
        this.userNo = params['email'];
      }
    );
    var token = localStorage.getItem('token');
    this.phone_country_code = this.matcoService.loadCountryCode();
    this.matcoService.getUserProfileBySuperUser(this.userNo, token)
      .subscribe(
        (data) => {
          this.user = data;
          // console.log(this.user);
          this.profileForm = this.formBuilder.group({
            fName: new FormControl(this.user.fName, [Validators.required, Validators.minLength(2)]),
            lName: new FormControl(this.user.lName, [Validators.required, Validators.minLength(2)]),
            email: new FormControl(this.user.email, [
              Validators.required,
              Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
            ]),
            userName: new FormControl(this.user.userName),
            address1: new FormControl(this.user.address1),
            address2: new FormControl(this.user.address2),
            companyName: new FormControl(this.user.companyName),
            currency: new FormControl(this.user.currency),
            postalCode: new FormControl(this.user.postalCode, [
              Validators.required
            //  Validators.pattern(/^[1-9]+[0-9]*$/)
            ]),
            phone_country_code: new FormControl(this.getCountryCode(this.user.telNo, this.user.telNoCode, this.user.country)),
            cellphone_country_code: new FormControl(this.getCountryCode(this.user.mobileNo, this.user.mobileNoCode, this.user.country)),
            country: new FormControl(this.user.country),
            mobileNo: new FormControl(this.getNumber(this.user.mobileNo,this.user.mobileNoCode)),
            telNo: new FormControl(this.getNumber(this.user.telNo,this.user.telNoCode)),
            role: new FormControl(this.user.role),
            cardCode: new FormControl(this.user.sapCardName+'-'+this.user.sapCardCode),
          });

          this.matcoService.getAllCompanies(this.user.companyName, this.user.currency).subscribe( companies => {
            this.companies = companies;
          })
        }
      );
      

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.roles = this.matcoService.getRoles();
  }

  saveProfile() {
    // var token = localStorage.getItem('token');
    this.send_status ='sending'; 
    this.matcoService.saveUserProfile(this.profileForm.value,this.userNo)
    .subscribe(
      (data) => {
        // console.log(data);
        this.send_status =''; 
        window.scroll(0,200);
        this.saved = true;
        setTimeout(()=>this.router.navigate(['/admin-users']),2000);
      }
    )
    
  }

  getCountryCode(phone, code, countryProfile) {

    if(phone) {
      // console.log(code)
      return code;
    } else {
      this.matcoService.loadCountryCode().forEach(country => {
        if(countryProfile == country.name) {
          // console.log(country.code)
          return country.code;
        }
      })
    }
  }

  getNumber(phone, code) {
    if(phone) {
      // console.log(phone.substring(code.length,phone.length))
      return phone.substring(code.length,phone.length);
    } else {
      return '';
    }
  }
}
