import { Component, OnInit, ViewChild, ElementRef,EventEmitter,Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray,FormGroupName, FormControl } from '@angular/forms';
import { Product } from 'src/app/model/product.model';
import { MatcoService } from 'src/app/services/matco.service';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  @ViewChild('loginModal') loginModal: ElementRef;
  products: Product[];
  result:any;
  authenticated: boolean = false;
  snackBarMsg:String;
  sent: boolean = false;
  additionalService:any;

  constructor(private formBuilder: FormBuilder,
              private matcoService: MatcoService,
              private router: Router) {
  }

  form: FormGroup;
  


  ngOnInit() {
  this.loadCart();
  this.loadAdditionalService();
    //this.matcoService.updateCartEmitter("loadcart");
    this.authenticated= this.matcoService.checkIfIsLogged();
    // this.matcoService.logged.subscribe(data =>{
    //     this.authenticated = data;
    // });
    
  }
  
  loadAdditionalService(){
    this.matcoService.getAdditionalRemarks().subscribe(data => {
      //console.log(data)
      this.additionalService = data[0];
      console.log(data);
      //console.log(this.additionalService);
    });
  }
  
  loadCart(){
    this.products = JSON.parse(localStorage.getItem("productsInCart"));
    this.form = this.formBuilder.group({
      credentials: this.formBuilder.array([]),
      
      additional: new FormControl(''),


      remarks: new FormControl(''),
      rem1 : new FormControl(),
      rem2 : new FormControl(),
      rem3 : new FormControl(),
      rem4 : new FormControl(),
      rem5 : new FormControl(),
      rem6 : new FormControl(),
      rmk: new FormControl('xxx')
    });

    for(var p in this.products  ){
      if (this.products.hasOwnProperty(p)) {
        this.addCreds(this.products[p]);
        
      }
    }
   
  }

    
  

  addCreds(product :Product) {
    const creds = this.form.controls.credentials as FormArray;
    var user_token:String = '';
    if(localStorage.getItem('user')){
      user_token = JSON.parse(localStorage.getItem('user')).Token 
    }
    
    creds.push(this.formBuilder.group({
      ItemName: product.itemName,
      Manufacturer: product.manufacturer,
      qty:product.qty,
      ItemGroup:product.itemGroup,
      Image: this.transform(product.image,'https://onematco.com/MatcoSAPB1WebAPI','assets'),
      ItemCode:product.itemCode,
      Available:  product.available,
      PartNo:product.partNo,
      userToken: user_token,
     
      
    }));
    
  }

  transform(value: string, regex: string, replaceValue: string): any {
    return value.replace(regex, replaceValue);
  }

  send_status = "";
  sendEnquiry(){

    this.send_status = "sending";
    var user_token: string = "";
    if(localStorage.getItem('user')){
      user_token = localStorage.getItem('token'); 
    }
    
    if(this.authenticated) {
      var InquiryProducts = this.form.controls.credentials.value;
      var remarks = this.form.get('remarks').value;
      
      if(this.form.get('rem1').value) {
        remarks = "Add Controls \n" + remarks;
      } 

      if(this.form.get('rem2').value) {
        remarks = "Add Painting \n" + remarks;
      }

      if(this.form.get('rem3').value) {
        remarks = "Perform Testing \n" + remarks;
      }

      if(this.form.get('rem4').value) {
        remarks = "Documentation and Certification \n" + remarks;
      }

      if(this.form.get('rem5').value) {
        remarks = "Vavle Operating System (VOS) \n" + remarks;
      }

      if(this.form.get('rem6').value) {
        remarks = "Actuated Ball Valve (ABV) \n" + remarks;
      }

      this.snackBarMsg = "Inquiry Sent";
      
     

      for(var i = 0; i < InquiryProducts.length; i++ ){
        InquiryProducts[i].Remarks = $("#desc" + i).val();
      }


 

      if(this.additionalService.RemarkDetails.length > 0){

      for(var i = 0; i < this.additionalService.RemarkDetails.length; i++ ){
        if($("#checkboxing-" + i)[0].checked) {
          if(remarks !== '') {
            remarks = remarks + ";" + $("#checkboxing-" + i).val();
          } else {
            remarks = $("#checkboxing-" + i).val();
          }
        }
      }

    }
 
     
    this.matcoService.sendInquiry(user_token,JSON.stringify(InquiryProducts), remarks)
      .subscribe(data => {
        if(data){
          localStorage.setItem("productsInCart", "{}");
          this.matcoService.updateCartEmitter("deletecart");
          this.loadCart();
          this.sent = true;
          this.send_status = '';
        }else{
          this.send_status = '';
        }
      
      }); 
    
     
    }
  }

  
  deleteProductInCart2(property: string){
    
    
    delete this.products[property];
    localStorage.setItem("productsInCart",JSON.stringify(this.products));
     this.products = JSON.parse(localStorage.getItem("productsInCart"));
    this.snackBarMsg = "Product removed from cart";
    this.matcoService.updateCartEmitter("deletecart");
    this.loadCart();
    
  }

  ngAfterViewInit(): void {
    //$.getScript('assets/js/custom.js');
    
  }

  changeQty(product, qty) {
    var currentproduct = JSON.parse(localStorage.getItem("productsInCart"));
    currentproduct[product.ItemCode].qty = qty;
    localStorage.setItem("productsInCart", JSON.stringify(currentproduct));
  }
}
