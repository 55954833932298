import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-admin-customers',
  templateUrl: './admin-customers.component.html',
  styleUrls: ['./admin-customers.component.css']
})
export class AdminCustomersComponent implements OnInit {

  constructor(
    private matcoService: MatcoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  
  customers = [];
  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.matcoService.getAllCustomers().subscribe(data => {
      this.customers.push(data);
      this.customers = this.customers[0].filter((customer) => customer.role === '' || customer.role === null || customer.role === 'CUSTOMER');
    });
  }

}
