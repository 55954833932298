import { Injectable,Output,EventEmitter  } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Customer } from '../model/customer.model';
import { Product } from '../model/product.model';
import { Login } from '../model/login.model';
import { User } from '../model/user.model';
import { Profile } from '../model/profile.model';
import { FormGroup } from '@angular/forms';



@Injectable({
  providedIn: 'root'
})
export class MatcoService {

  

  customer: Customer;
  productsInCart: Product[] = [];
  @Output() cartStorage: EventEmitter<Object> = new EventEmitter();
  @Output() logged: EventEmitter<Boolean> = new EventEmitter();
  @Output() dbFlag: EventEmitter<Boolean> = new EventEmitter();
  @Output() cartEmitter : EventEmitter<String> = new EventEmitter();

  // webHost: string  = "https://onematco.com/";
  // webAppName: String = "MatcoSAPB1WebAPI/";
  // webServiceInventory:string = "Inventory.asmx";
  // webServiceCustomer:string = "Customer.asmx";
  // matcoWebServiceApi: string =  this.webHost + this.webAppName + this.webServiceInventory;
  // matcoWebServiceApi: string =  this.webHost + this.webAppName + this.webServiceCustomer;

  matcoWebServiceApi: String = "https://matcowebapi.azurewebsites.net/api";

  private getObtainHeaders() {
    let obtainHeader = new HttpHeaders();
    obtainHeader = obtainHeader.append('Content-type', 'application/json');
    obtainHeader = obtainHeader.append('Authorization', localStorage.getItem("token"));
    return obtainHeader;
  }

  constructor(private http: HttpClient) { }

  registerCustomer(postform : any) {
    
    const body = {
      "email" : postform.email,
      "firstName" : postform.firstName,
      "lastName" : postform.lastName,
      "companyName" : postform.companyName,
      "companyAddress1" : postform.companyAddress1,
      "country" : postform.country,
      "postalCode" : postform.postalCode,
      "currency" : postform.currency,
      "mobileNo" : postform.cellphone_country_code + ''+postform.mobileNo,
      "telephoneNo" : postform.phone_country_code + '' +postform.telephoneNo,
      "displayUsername" : postform.displayUsername,
      "password" : postform.password,
      "confirmPassword" : postform.confirmPassword,
      "companyAddress2" : postform.companyAddress2,
      "customerCategory" : '',
      "mobileNoCode" : postform.cellphone_country_code,
      "telephoneNoCode" : postform.phone_country_code
    }

    // console.log(body)

    return this.http.post(this.matcoWebServiceApi+"/Account/Register", body,
    {headers: new HttpHeaders().set('Content-Type','application/json')});
  }

  getAllProducts(){
    return this.http.get(this.matcoWebServiceApi+"/Inventory/InventoryList");
  }

  getBrand(){
      return this.http.get(this.matcoWebServiceApi+"/Inventory/Manufacturer");
  }

  getProductListByBrand(brand: string) {
    return this.http.get(this.matcoWebServiceApi+"/Inventory/InventoryListByBrand?brand="+brand);
  }

  getProductDetails(itemCode: string) {
    
    return this.http.get(this.matcoWebServiceApi+"/Inventory/ItemDetailed?ItemCode="+itemCode);
  }

  getProductDetailsSapCardCode(itemCode: string, sapCardCode: string) {
    
    return this.http.get(this.matcoWebServiceApi+"/Inventory/ItemDetailedwithPrice?ItemCode="+itemCode+"&SAPCardCode="+sapCardCode);
  }

  getCategoriesByBrand(brand: string) {
    return this.http.get(this.matcoWebServiceApi+"/Inventory/CategoryByManufacturer?Brand="+brand);
  }

  getProductsByCategory(category: string, brand: string) {
    return this.http.get(this.matcoWebServiceApi+"/Inventory/InventoryListByCategory?Category="+category+"&Brand="+brand);
  }

  getProductsByPartAndBrand(manufacturer: string ,partno: string ){
   return this.http.get(this.matcoWebServiceApi+"/Inventory/InventoryListSearchPartAndBrand?sPartno="+partno+"&sBrand="+manufacturer);
  }

  getProductsByPartAndBrand2(manufacturer: string ,partno: string ){
    
    return this.http.get(this.matcoWebServiceApi+"/Inventory/InventoryListSearchPartAndBrandTop10?sPartNo="+partno+"&sBrand="+manufacturer);
   
    
   }

  getAllCountries() {
    return this.http.get(this.matcoWebServiceApi+"/Customer/Countries");
  }

  getAllCurrencies() {
    return this.http.get(this.matcoWebServiceApi+"/Customer/Currency");
  }

  getAdditionalRemarks(){

    return this.http.get(this.matcoWebServiceApi+"/AdditionalServiceRemarks",
    );

  }



  
  



  login(loginForm: Login) {
    const body = {
      "email" : loginForm.email,
      "password" : loginForm.password
    }

    return this.http.post(this.matcoWebServiceApi+"/account/login", body,
    {
      headers: new HttpHeaders().set('Content-Type','application/json')
    }).subscribe(
      
      (user: User) => {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token",user.token_type + " " + user.access_token);
        this.checkIfIsLogged();
      }, (error) => {
        this.checkIfIsLogged();
        // console.log(error);
      }
    );
   
  }

  auth:any;

  checkIfIsLogged(){
    // const body = new HttpParams()
    // .set('Token', localStorage.getItem("token"));
    // this.http.post(this.matcoWebServiceApi+"/Authentication", body.toString(),
    // {
    //   headers: new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
    // }
    // ).subscribe(data =>{
    //   this.auth = data[0];
    //   if(this.auth.Authentication == "TRUE"){
    //       this.logged.emit(true);
          

    //   }else{
    //       this.logged.emit(false);
          
    //   }
    // })

    if(localStorage.getItem("token")) {
       this.logged.emit(true);
        return true;
    } else {
      this.logged.emit(false);
      return false;
    }
  }

  checkIfProductsFromDB(dbFlag : boolean) {
    if(dbFlag) {
      this.dbFlag.emit(true);
      return true;
    } else {
      this.dbFlag.emit(false);
      return false;
    }
      
  }

  checkLoading() {
    
  }
  
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    this.checkIfIsLogged();
  }
  

  searchProductByPartNumber(partNo) {
    
    const body = {
      "sPartNo" : partNo
    }

    return this.http.post(this.matcoWebServiceApi+"/Inventory/InventoryListSearchPartNo", body,
    {headers: this.getObtainHeaders()});
  }


  // carteEmitter()
  
  updateCartEmitter(action){
    this.cartEmitter.emit(action);
  }

  sendInquiry(token: string, productList: string, remarks: string) {
    // const body = new HttpParams()
    // .set('Token',token)
    // .set('ProductList', productList)
    // .set('sRemarks', remarks);

    const body = {
      "token" : token,
      "ProductList" : productList,
      "sRemarks" : remarks
    }

    return this.http.post(this.matcoWebServiceApi+"/DTJsonSubmitInquiry", body,
    {headers: this.getObtainHeaders()});
  }


  sendInquiry2(inquiries: string) {

    return this.http.post(this.matcoWebServiceApi+"/Inquiry/SubmitInquiry", JSON.parse(inquiries),
    {headers: this.getObtainHeaders()});
  }

  getAllInquiriesByCustomerEmail(email: string) {

    return this.http.get(this.matcoWebServiceApi+"/Inquiry/MyInquiry?Email=" + email,
    {headers: this.getObtainHeaders()});
  }
 
  getInquiryDetail(token: string, docEntry: string) {
    // const body = new HttpParams()
    // .set('Token',token)
    // .set('DocEntry', DocEntry);

    const body = {
      "token" : token,
      "docEntry" : docEntry
    }

    return this.http.post(this.matcoWebServiceApi+"/DTJsonViewInquiryDetailsUserName", body,
    {headers: this.getObtainHeaders()});
  }

  getAllInquiries(token: string) {

    return this.http.get(this.matcoWebServiceApi+"/Inquiry/InquiryAdmin",
    {headers: this.getObtainHeaders()});
  }
  

  getUserProfile(userNo: string) {

    return this.http.get(this.matcoWebServiceApi+"/Customer/CustomerProfile?UserNo="+userNo,
    {headers: this.getObtainHeaders()});
  }

  saveProfile(postform, userNo: string) {
    // const body = new HttpParams()
    // .set('Token', token)
    // .set('sFirstName', postform.fName)
    // .set('sLastName', postform.lName)
    // .set('sCompanyName', postform.companyName)
    // .set('sEmail', postform.email)
    // .set('sCompanyAddress1', postform.address)
    // .set('sPostalCode', postform.postalCode)
    // .set('sCountry', postform.country)
    // .set('sCurrency', postform.currency)
    // .set('sMobileNo', postform.mobileNo)
    // .set('sTelephoneNo', postform.telNo)
    // .set('sUserName', postform.userName);

    const body = {
      "fName" : postform.fName,
      "lName" : postform.lName,
      "email" : postform.email,
      "userName" : postform.userName,
      "address1" : postform.address1,
      "address2" : postform.address2,
      "companyName" : postform.companyName,
      "postalCode" : postform.postalCode,
      "country" : postform.country,
      "telNo" : postform.phone_country_code + postform.telNo,
      "mobileNo" : postform.cellphone_country_code + postform.mobileNo,
      "currency" : postform.currency,
      "mobileNoCode" : postform.cellphone_country_code,
      "telNoCode" : postform.phone_country_code
    }

    // console.log(body)
    return this.http.post(this.matcoWebServiceApi+"/Customer/UpdateCustomerProfile?UserNo="+userNo, body,
    {headers: this.getObtainHeaders()});
    
  }



  // administrator


  getAllCustomers(){

    return this.http.get(this.matcoWebServiceApi+"/Customer/CustomerList",
    {headers: this.getObtainHeaders()});
  }


  getAllAdminInquiries(){

    return this.http.get(this.matcoWebServiceApi+"/Inquiry/InquiryAdmin",
    {headers: this.getObtainHeaders()});
  }


  getAllCompanies(companyName, currency){

    return this.http.get(this.matcoWebServiceApi+"/Customer/SAPCustomerCode?CompanyName="+companyName+"&Currency="+currency,
    {headers: this.getObtainHeaders()});
  }


  updateSapCardCode(docNum,sapCardCode,sapCardName,priceList,currency,userNo){

    const body = {
      "docNum" : docNum,
      "sapCardCode" : sapCardCode,
      "sapCardName" : sapCardName,
      "priceList" : priceList,
      "currency" : currency,
      "userNo" : userNo
    }

    return this.http.post(this.matcoWebServiceApi+"/Inquiry/UpdateInquirySAPCardCode", body,
    {headers: this.getObtainHeaders()});
  }


  createQuotation(docNum){
    let userNo = JSON.parse(localStorage.getItem('user')).userNo;
    // let userNo = 1;
    return this.http.post(this.matcoWebServiceApi+"/Inquiry/UpdateInquiryCreateSalesQuotation?DocNum="+docNum+"&UserNo="+userNo,
    {headers: this.getObtainHeaders()});
  }


  reSendMail(docNum,userNo){

    // const body = new HttpParams()
    // .set('Token', localStorage.getItem("token"))
    // .set('DocNum', DocNum);

    // const body = {
    //   "token" : localStorage.getItem("token"),
    //   "docNum" : docNum
    // }
    
    return this.http.post(this.matcoWebServiceApi+"/Inquiry/UpdateInquiryEmailSalesQuotation?DocNum="+docNum+"&UserNo="+userNo,
    {headers: this.getObtainHeaders()});

}


  getInquiriesByID(id:string){

    return this.http.get(this.matcoWebServiceApi+"/Inquiry/InquiryDetails?DocNum="+id,
    {headers: this.getObtainHeaders()});
  }

  sendPasswordtoEmail(forgotPassForm) {
    // const body = new HttpParams()
    // .set('sEmail',forgotPassForm.email);

    const body = {
      "email" : forgotPassForm.email
    }

    return this.http.post(this.matcoWebServiceApi+"/Account/ForgotPassword", body);
  }

  resetPassword(form, code) {
    const body = {
     "email" : form.email,
     "password" : form.password,
     "confirmPassword" : form.confirmPassword,
     "code" : code 
    }

    return this.http.post(this.matcoWebServiceApi+"/Account/ResetPassword", body);
  }

  resendEmailValidation(email) {
    // const body = new HttpParams()
    // .set('sEmail',email);

    const body = {
      "email" : email
    }
    // console.log(body)
    return this.http.post(this.matcoWebServiceApi+"/Account/ResendEmail", body);
    // {headers: this.getObtainHeaders()});
  }

  sendNewsLetter(email){
    // const body = new HttpParams()
    // .set('sEmail',email);

    const body = {
      "Email" : email
    }

    return this.http.post(this.matcoWebServiceApi+"/NewsLetter", body,
    {headers: this.getObtainHeaders()});
  }

  changePassword(email,token,newPassword) {
    // const body = new HttpParams()
    // .set('Token',token)
    // .set('sEmail',email)
    // .set('sNewPassword',newPassword.password);

    const body = {
      "oldPassword" : "",
      "confirmPassword" : newPassword.password,
      "newPassword" : newPassword.password
    }

    return this.http.post(this.matcoWebServiceApi+"/Account/ChangePassword", body,
    {headers: this.getObtainHeaders()});
  }

  emailValidation(email: string, userNo: string) {
    
    return this.http.get(this.matcoWebServiceApi+"/Account/ConfirmEmailLink?Email="+email+"&UserId="+userNo);
  }

  getUserProfileBySuperUser(userNo, token) {

    return this.http.get(this.matcoWebServiceApi+"/Customer/CustomerProfile?UserNo="+userNo,
    {headers: this.getObtainHeaders()});
  }

  getRoles(){
    return ['USER', 'SUPERUSER', 'CUSTOMER'];
  }

  saveUserProfile(postform , userNo: string) {

    var SAPInfo = postform.cardCode.split('-');

    const body = {
      "fName" : postform.fName,
      "lName": postform.lName,
      "email" : postform.email,
      "userName" : postform.userName,
      "address1" : postform.address1,
      "address2" : postform.address2,
      "companyName" : postform.companyName,
      "currency" : postform.currency,
      "postalCode" : postform.postalCode,
      "country" : postform.country,
      "mobileNo" : postform.cellphone_country_code + '' +postform.mobileNo,
      "telNo" :postform.phone_country_code +''+ postform.telNo,
      "SAPCardCode" : SAPInfo[1],
      "SAPCardName" :  SAPInfo[0],
      "role" : postform.role,
      "mobileNoCode" : postform.cellphone_country_code,
      "telNoCode" : postform.phone_country_code
    }

    return this.http.post(this.matcoWebServiceApi+"/Customer/UpdateCustomerSAPB1Data?UserNo="+userNo, body,
    {headers: this.getObtainHeaders()});
    
  }
  
  getAllUsers(token: string) {

    return this.http.get(this.matcoWebServiceApi+"/Customer/CustomerList",
    {headers: this.getObtainHeaders()});
  }

  createUser(postform , token: string) {
    const body = new HttpParams()
    .set('Token', token)
    .set('sFirstName', postform.FName)
    .set('sLastName', postform.LName)
    .set('sCompanyName', postform.CompanyName)
    .set('sEmail', postform.Email)
    .set('sCompanyAddress1', postform.Address)
    .set('sPostalCode', postform.PostalCode)
    .set('sCountry', postform.Country)
    .set('sMobileNo', postform.MobileNo)
    .set('sTelephoneNo', postform.TelNo)
    .set('sUserName', postform.UserName)
    .set('sPassword', postform.Password)
    .set('sAssignedRole', postform.Role);

    return this.http.post(this.matcoWebServiceApi+"/DTJsonCreateUserProfileAsSuperUser", body.toString(),
    {headers: this.getObtainHeaders()});
    
  }

  getCustomerProfile(token: string, userNo: string) {

    return this.http.get(this.matcoWebServiceApi+"/Customer/CustomerProfile?UserNo="+userNo,
    {headers: this.getObtainHeaders()});
  }

  saveCustomerProfile(postform, userNo: string) {
    // console.log(postform.cardCode)
    var SAPInfo = postform.cardCode.split('-');

    const body = {
      "fName" : postform.fName,
      "lName": postform.lName,
      "email" : postform.email,
      "userName" : postform.userName,
      "address1" : postform.address1,
      "address2" : postform.address2,
      "companyName" : postform.companyName,
      "currency" : postform.currency,
      "postalCode" : postform.postalCode,
      "country" : postform.country,
      "mobileNo" : postform.cellphone_country_code + '' +postform.mobileNo,
      "telNo" :postform.phone_country_code +''+ postform.telNo,
      "SAPCardCode" : SAPInfo[1],
      "SAPCardName" :  SAPInfo[0],
      "role" : postform.role,
      "mobileNoCode" : postform.cellphone_country_code,
      "telNoCode" : postform.phone_country_code,
      "priceList" : postform.priceList,
      "paymentTerms" : postform.paymentTerms
    }

    // console.log(body)

    return this.http.post(this.matcoWebServiceApi+"/Customer/UpdateCustomerSAPB1Data?UserNo="+userNo, body,
    {headers: this.getObtainHeaders()});
  }

  //Order API

  getAllOrdersPerUser(email: string) {
    return this.http.get(this.matcoWebServiceApi+"/Order/MyOrder?Email="+email, {headers: this.getObtainHeaders()});
  }

  getUserOrderByEmail(email: string) {
    return this.http.get(this.matcoWebServiceApi+"/Order/MyOpenOrder?Email="+email, {headers: this.getObtainHeaders()});
  }

  saveOrder(orders: string, docTotal: String, discount) {
    var order = JSON.parse(orders);
    order['docTotal'] = docTotal;
    order['discountAmount'] = discount;

    return this.http.post(this.matcoWebServiceApi+"/Order/SaveOrder", order,
    {headers: this.getObtainHeaders()});
  }

  updateOrder(orders: string, docNum: string, docTotal: String, discount) {
    var order = JSON.parse(orders);
    order['docNum'] = docNum;
    order['docTotal'] = docTotal;
    order['discountAmount'] = discount;
    // console.log(order)

    return this.http.post(this.matcoWebServiceApi+"/Order/UpdateOrder", order,
    {headers: this.getObtainHeaders()});
  }

  getAllOrdersAdmin() {
    return this.http.get(this.matcoWebServiceApi+"/Order/OrderListAdmin", {headers: this.getObtainHeaders()});
  }

  getOrderDetailsAdmin(docNum) {
    return this.http.get(this.matcoWebServiceApi+"/Order/OrderDetailsAdmin?DocNum=" + docNum, {headers: this.getObtainHeaders()});
  }

  //Shipping API
  saveShippingDetails(shippingDetails, userNo, orderNo, country) {
    // console.log(shippingDetails)
    const body  = {
      "shipToAddress1" : shippingDetails.companyAddress1,
      "shipToAddress2" : shippingDetails.companyAddress2,
      "shipToCountry" : country,
      "shipToPostalCode" : shippingDetails.postalCode,
      "shipmentRemarks" : shippingDetails.remarks,
      "userNo" : userNo,
      "docNum" : orderNo
    }
    // console.log(body)
    return this.http.post(this.matcoWebServiceApi+"/Order/UpdateShippingDetails", body,
    {headers: this.getObtainHeaders()});
  }

  payment(paymentMethodId, paymentIntentId, docTotal, currency, orderNo, companyName) {

    const body = {
      "payment_intent_id" : paymentIntentId,
      "payment_method_id" : paymentMethodId,
      "amount" : parseFloat(docTotal),
      "currency" : currency,
      "orderNo" : orderNo,
      "companyName" : companyName
    }
    return this.http.post(this.matcoWebServiceApi+"/Payment", body,
    {headers: this.getObtainHeaders()});
  }

  updatePayment(paymentIntentObj, userNo, docNum, is3D:boolean, paymentType) {
    // console.log(is3D)
    // console.log(paymentIntentObj)
    const body = {
      "name" : is3D ? paymentIntentObj.paymentIntent.name :paymentIntentObj.charges.data[0].billing_details.name,
      "email": is3D ? paymentIntentObj.paymentIntent.email : paymentIntentObj.charges.data[0].billing_details.email,
      "mobileNo" : is3D ? paymentIntentObj.paymentIntent.phone : paymentIntentObj.charges.data[0].billing_details.phone,
      "billToAddress1" : is3D ? paymentIntentObj.paymentIntent.line1 : paymentIntentObj.charges.data[0].billing_details.address.line1,
      "billToAddress2" : is3D ? paymentIntentObj.paymentIntent.line2 : paymentIntentObj.charges.data[0].billing_details.address.line2,
      "billToCountry" : is3D ? paymentIntentObj.paymentIntent.country : paymentIntentObj.charges.data[0].billing_details.address.country,
      "billToPostalCode" : is3D ? paymentIntentObj.paymentIntent.postalCode : paymentIntentObj.charges.data[0].billing_details.address.line2,
      "paymentType" : is3D ? paymentIntentObj.paymentIntent.payment_method_types[0] : paymentIntentObj.payment_method_types[0],
      "paymentTransNo" : is3D ? paymentIntentObj.paymentIntent.id : paymentIntentObj.id,
      "paymentDate" : is3D ? paymentIntentObj.paymentIntent.created.toString() : paymentIntentObj.created.toString(),
      "paymentStatus" : is3D ? paymentIntentObj.paymentIntent.status :paymentIntentObj.status,
      "paymentDetails" : is3D ? (paymentIntentObj.paymentIntent.amount/100) : (paymentIntentObj.amount/100),
      "paymentRemarks" : paymentType,
      "userNo" : userNo,
      "docNum" : docNum
    }
    // console.log(body)
    return this.http.post(this.matcoWebServiceApi+"/Order/UpdatePaymentDetails", body,
    {headers: this.getObtainHeaders()});
  }

  updateFullPayment(paymentIntentObj, userNo, docNum, is3D:boolean, paymentType) {
    // console.log(is3D)
    // console.log(paymentIntentObj)
    const body = {
      "name" : is3D ? paymentIntentObj.paymentIntent.name :paymentIntentObj.charges.data[0].billing_details.name,
      "email": is3D ? paymentIntentObj.paymentIntent.email : paymentIntentObj.charges.data[0].billing_details.email,
      "mobileNo" : is3D ? paymentIntentObj.paymentIntent.phone : paymentIntentObj.charges.data[0].billing_details.phone,
      "billToAddress1" : is3D ? paymentIntentObj.paymentIntent.line1 : paymentIntentObj.charges.data[0].billing_details.address.line1,
      "billToAddress2" : is3D ? paymentIntentObj.paymentIntent.line2 : paymentIntentObj.charges.data[0].billing_details.address.line2,
      "billToCountry" : is3D ? paymentIntentObj.paymentIntent.country : paymentIntentObj.charges.data[0].billing_details.address.country,
      "billToPostalCode" : is3D ? paymentIntentObj.paymentIntent.postalCode : paymentIntentObj.charges.data[0].billing_details.address.line2,
      "paymentType" : is3D ? paymentIntentObj.paymentIntent.payment_method_types[0] : paymentIntentObj.payment_method_types[0],
      "paymentTransNo" : is3D ? paymentIntentObj.paymentIntent.id : paymentIntentObj.id,
      "paymentDate" : is3D ? paymentIntentObj.paymentIntent.created.toString() : paymentIntentObj.created.toString(),
      "paymentStatus" : is3D ? paymentIntentObj.paymentIntent.status :paymentIntentObj.status,
      "paymentDetails" : is3D ? (paymentIntentObj.paymentIntent.amount/100) : (paymentIntentObj.amount/100),
      "paymentRemarks" : paymentType,
      "userNo" : userNo,
      "docNum" : docNum
    }
    // console.log(body)
    return this.http.post(this.matcoWebServiceApi+"/Order/UpdateFullPaymentDetails", body,
    {headers: this.getObtainHeaders()});
  }


  viewOrder(orderId) {
    return this.http.get(this.matcoWebServiceApi+"/Order/PrintReceipt?id="+orderId, {headers: this.getObtainHeaders()});
  }

  resendEmail(docNum) {
    return this.http.get(this.matcoWebServiceApi+"/Order/ResendPDFOrder?DocNum="+docNum, {headers: this.getObtainHeaders()});
  }

  getSubitemList() {
    return this.http.get(this.matcoWebServiceApi+"/Inventory/SubItemCodeList", {headers: this.getObtainHeaders()});
  }

  getSubitemDetails(itemCode, sapCardCode) {
    return this.http.get(this.matcoWebServiceApi+"/Inventory/SubItemDetailedwithPrice?ItemCode="+itemCode+"&SAPCardCode="+sapCardCode, {headers: this.getObtainHeaders()});
  }

  updateValidationDetails(remarks, details, docNum, userNo) {

    const body = {
      "validationDetails" : details.value,
      "validationRemarks" : remarks.value,
      "docNum" : docNum,
      "userNo" : userNo
    }
    // console.log(body);
    return this.http.post(this.matcoWebServiceApi+"/Order/UpdateValidationDetails", body,
    {headers: this.getObtainHeaders()});
  }

  addSalesOrder(docNum, userNo) {

    return this.http.post(this.matcoWebServiceApi+"/Order/AddSalesOrder?DocNum="+docNum+"&UserNo="+userNo,
    {headers: this.getObtainHeaders()});
  }

  addToCartDB(products, userNo) {
    products = JSON.parse(products)
   
    const body = {
      "secretProducts" : products.secretProducts,
      "userNo" : userNo 
    }
    
    // console.log(body)
    // console.log(JSON.stringify(body))
    return this.http.post(this.matcoWebServiceApi+"/Order/AddToCart", body,
    {headers: this.getObtainHeaders()});
  }

  loadCountryCode(){
    
    return  [
      //  {
      //    "code": "+7 840",
      //    "name": "Abkhazia"
      //  },
      //  {
      //    "code": "+93",
      //    "name": "Afghanistan"
      //  },
      //  {
      //    "code": "+355",
      //    "name": "Albania"
      //  },
      //  {
      //    "code": "+213",
      //    "name": "Algeria"
      //  },
      //  {
      //    "code": "+1 684",
      //    "name": "American Samoa"
      //  },
      //  {
      //    "code": "+376",
      //    "name": "Andorra"
      //  },
      //  {
      //    "code": "+244",
      //    "name": "Angola"
      //  },
      //  {
      //    "code": "+1 264",
      //    "name": "Anguilla"
      //  },
      //  {
      //    "code": "+1 268",
      //    "name": "Antigua and Barbuda"
      //  },
       {
         "code": "+54",
         "name": "Argentina"
       },
       {
         "code": "+374",
         "name": "Armenia"
       },
       {
         "code": "+297",
         "name": "Aruba"
       },
       {
         "code": "+247",
         "name": "Ascension"
       },
       {
         "code": "+61",
         "name": "Australia"
       },
       {
         "code": "+672",
         "name": "Australian External Territories"
       },
       {
         "code": "+43",
         "name": "Austria"
       },
       {
         "code": "+994",
         "name": "Azerbaijan"
       },
      //  {
      //    "code": "+1 242",
      //    "name": "Bahamas"
      //  },
       {
         "code": "+973",
         "name": "Bahrain"
       },
       {
         "code": "+880",
         "name": "Bangladesh"
       },
      //  {
      //    "code": "+1 246",
      //    "name": "Barbados"
      //  },
      //  {
      //    "code": "+1 268",
      //    "name": "Barbuda"
      //  },
       {
         "code": "+375",
         "name": "Belarus"
       },
       {
         "code": "+32",
         "name": "Belgium"
       },
       {
         "code": "+501",
         "name": "Belize"
       },
       {
         "code": "+229",
         "name": "Benin"
       },
      //  {
      //    "code": "+1 441",
      //    "name": "Bermuda"
      //  },
       {
         "code": "+975",
         "name": "Bhutan"
       },
      //  {
      //    "code": "+591",
      //    "name": "Bolivia"
      //  },
      //  {
      //    "code": "+387",
      //    "name": "Bosnia and Herzegovina"
      //  },
      //  {
      //    "code": "+267",
      //    "name": "Botswana"
      //  },
       {
         "code": "+55",
         "name": "Brazil"
       },
       {
         "code": "+246",
         "name": "British Indian Ocean Territory"
       },
      //  {
      //    "code": "+1 284",
      //    "name": "British Virgin Islands"
      //  },
       {
         "code": "+673",
         "name": "Brunei"
       },
       {
         "code": "+359",
         "name": "Bulgaria"
       },
       {
         "code": "+226",
         "name": "Burkina Faso"
       },
       {
         "code": "+257",
         "name": "Burundi"
       },
       {
         "code": "+855",
         "name": "Cambodia"
       },
       {
         "code": "+237",
         "name": "Cameroon"
       },
       {
         "code": "+1",
         "name": "Canada"
       },
       {
         "code": "+238",
         "name": "Cape Verde"
       },
       {
         "code": "+ 345",
         "name": "Cayman Islands"
       },
       {
         "code": "+236",
         "name": "Central African Republic"
       },
       {
         "code": "+235",
         "name": "Chad"
       },
       {
         "code": "+56",
         "name": "Chile"
       },
       {
         "code": "+86",
         "name": "China"
       },
       {
         "code": "+61",
         "name": "Christmas Island"
       },
       {
         "code": "+61",
         "name": "Cocos-Keeling Islands"
       },
       {
         "code": "+57",
         "name": "Colombia"
       },
      //  {
      //    "code": "+269",
      //    "name": "Comoros"
      //  },
      //  {
      //    "code": "+242",
      //    "name": "Congo"
      //  },
      //  {
      //    "code": "+243",
      //    "name": "Congo, Dem. Rep. of (Zaire)"
      //  },
      //  {
      //    "code": "+682",
      //    "name": "Cook Islands"
      //  },
       {
         "code": "+506",
         "name": "Costa Rica"
       },
       {
         "code": "+385",
         "name": "Croatia"
       },
       {
         "code": "+53",
         "name": "Cuba"
       },
       {
         "code": "+599",
         "name": "Curacao"
       },
       {
         "code": "+537",
         "name": "Cyprus"
       },
       {
         "code": "+420",
         "name": "Czech Republic"
       },
       {
         "code": "+45",
         "name": "Denmark"
       },
       {
         "code": "+246",
         "name": "Diego Garcia"
       },
       {
         "code": "+253",
         "name": "Djibouti"
       },
      //  {
      //    "code": "+1 767",
      //    "name": "Dominica"
      //  },
      //  {
      //    "code": "+1 809",
      //    "name": "Dominican Republic"
      //  },
       {
         "code": "+670",
         "name": "East Timor"
       },
       {
         "code": "+56",
         "name": "Easter Island"
       },
       {
         "code": "+593",
         "name": "Ecuador"
       },
       {
         "code": "+20",
         "name": "Egypt"
       },
       {
         "code": "+503",
         "name": "El Salvador"
       },
       {
         "code": "+240",
         "name": "Equatorial Guinea"
       },
       {
         "code": "+291",
         "name": "Eritrea"
       },
       {
         "code": "+372",
         "name": "Estonia"
       },
       {
         "code": "+251",
         "name": "Ethiopia"
       },
       {
         "code": "+500",
         "name": "Falkland Islands"
       },
       {
         "code": "+298",
         "name": "Faroe Islands"
       },
       {
         "code": "+679",
         "name": "Fiji"
       },
       {
         "code": "+358",
         "name": "Finland"
       },
       {
         "code": "+33",
         "name": "France"
       },
       {
         "code": "+596",
         "name": "French Antilles"
       },
       {
         "code": "+594",
         "name": "French Guiana"
       },
       {
         "code": "+689",
         "name": "French Polynesia"
       },
       {
         "code": "+241",
         "name": "Gabon"
       },
       {
         "code": "+220",
         "name": "Gambia"
       },
       {
         "code": "+995",
         "name": "Georgia"
       },
       {
         "code": "+49",
         "name": "Germany"
       },
       {
         "code": "+233",
         "name": "Ghana"
       },
       {
         "code": "+350",
         "name": "Gibraltar"
       },
       {
         "code": "+30",
         "name": "Greece"
       },
       {
         "code": "+299",
         "name": "Greenland"
       },
       {
         "code": "+1 473",
         "name": "Grenada"
       },
       {
         "code": "+590",
         "name": "Guadeloupe"
       },
       {
         "code": "+1 671",
         "name": "Guam"
       },
       {
         "code": "+502",
         "name": "Guatemala"
       },
       {
         "code": "+224",
         "name": "Guinea"
       },
       {
         "code": "+245",
         "name": "Guinea-Bissau"
       },
       {
         "code": "+595",
         "name": "Guyana"
       },
       {
         "code": "+509",
         "name": "Haiti"
       },
       {
         "code": "+504",
         "name": "Honduras"
       },
       {
         "code": "+852",
         "name": "Hong Kong SAR China"
       },
       {
         "code": "+36",
         "name": "Hungary"
       },
       {
         "code": "+354",
         "name": "Iceland"
       },
       {
         "code": "+91",
         "name": "India"
       },
       {
         "code": "+62",
         "name": "Indonesia"
       },
       {
         "code": "+98",
         "name": "Iran"
       },
       {
         "code": "+964",
         "name": "Iraq"
       },
       {
         "code": "+353",
         "name": "Ireland"
       },
       {
         "code": "+972",
         "name": "Israel"
       },
       {
         "code": "+39",
         "name": "Italy"
       },
       {
         "code": "+225",
         "name": "Ivory Coast"
       },
       {
         "code": "+1 876",
         "name": "Jamaica"
       },
       {
         "code": "+81",
         "name": "Japan"
       },
       {
         "code": "+962",
         "name": "Jordan"
       },
       {
         "code": "+7 7",
         "name": "Kazakhstan"
       },
       {
         "code": "+254",
         "name": "Kenya"
       },
       {
         "code": "+686",
         "name": "Kiribati"
       },
       {
         "code": "+965",
         "name": "Kuwait"
       },
       {
         "code": "+996",
         "name": "Kyrgyzstan"
       },
       {
         "code": "+856",
         "name": "Laos"
       },
       {
         "code": "+371",
         "name": "Latvia"
       },
       {
         "code": "+961",
         "name": "Lebanon"
       },
       {
         "code": "+266",
         "name": "Lesotho"
       },
       {
         "code": "+231",
         "name": "Liberia"
       },
       {
         "code": "+218",
         "name": "Libya"
       },
       {
         "code": "+423",
         "name": "Liechtenstein"
       },
       {
         "code": "+370",
         "name": "Lithuania"
       },
       {
         "code": "+352",
         "name": "Luxembourg"
       },
       {
         "code": "+853",
         "name": "Macau SAR China"
       },
       {
         "code": "+389",
         "name": "Macedonia"
       },
       {
         "code": "+261",
         "name": "Madagascar"
       },
       {
         "code": "+265",
         "name": "Malawi"
       },
       {
         "code": "+60",
         "name": "Malaysia"
       },
       {
         "code": "+960",
         "name": "Maldives"
       },
       {
         "code": "+223",
         "name": "Mali"
       },
       {
         "code": "+356",
         "name": "Malta"
       },
       {
         "code": "+692",
         "name": "Marshall Islands"
       },
       {
         "code": "+596",
         "name": "Martinique"
       },
       {
         "code": "+222",
         "name": "Mauritania"
       },
       {
         "code": "+230",
         "name": "Mauritius"
       },
       {
         "code": "+262",
         "name": "Mayotte"
       },
       {
         "code": "+52",
         "name": "Mexico"
       },
       {
         "code": "+691",
         "name": "Micronesia"
       },
       {
         "code": "+1 808",
         "name": "Midway Island"
       },
       {
         "code": "+373",
         "name": "Moldova"
       },
       {
         "code": "+377",
         "name": "Monaco"
       },
       {
         "code": "+976",
         "name": "Mongolia"
       },
       {
         "code": "+382",
         "name": "Montenegro"
       },
       {
         "code": "+1664",
         "name": "Montserrat"
       },
       {
         "code": "+212",
         "name": "Morocco"
       },
       {
         "code": "+95",
         "name": "Myanmar"
       },
       {
         "code": "+264",
         "name": "Namibia"
       },
       {
         "code": "+674",
         "name": "Nauru"
       },
       {
         "code": "+977",
         "name": "Nepal"
       },
       {
         "code": "+31",
         "name": "Netherlands"
       },
       {
         "code": "+599",
         "name": "Netherlands Antilles"
       },
       {
         "code": "+1 869",
         "name": "Nevis"
       },
       {
         "code": "+687",
         "name": "New Caledonia"
       },
       {
         "code": "+64",
         "name": "New Zealand"
       },
      //  {
      //    "code": "+505",
      //    "name": "Nicaragua"
      //  },
      //  {
      //    "code": "+227",
      //    "name": "Niger"
      //  },
      //  {
      //    "code": "+234",
      //    "name": "Nigeria"
      //  },
      //  {
      //    "code": "+683",
      //    "name": "Niue"
      //  },
      //  {
      //    "code": "+672",
      //    "name": "Norfolk Island"
      //  },
       {
         "code": "+850",
         "name": "North Korea"
       },
       {
         "code": "+1 670",
         "name": "Northern Mariana Islands"
       },
       {
         "code": "+47",
         "name": "Norway"
       },
       {
         "code": "+968",
         "name": "Oman"
       },
       {
         "code": "+92",
         "name": "Pakistan"
       },
       {
         "code": "+680",
         "name": "Palau"
       },
       {
         "code": "+970",
         "name": "Palestinian Territory"
       },
       {
         "code": "+507",
         "name": "Panama"
       },
       {
         "code": "+675",
         "name": "Papua New Guinea"
       },
       {
         "code": "+595",
         "name": "Paraguay"
       },
       {
         "code": "+51",
         "name": "Peru"
       },
       {
         "code": "+63",
         "name": "Philippines"
       },
       {
         "code": "+48",
         "name": "Poland"
       },
       {
         "code": "+351",
         "name": "Portugal"
       },
       {
         "code": "+1 787",
         "name": "Puerto Rico"
       },
       {
         "code": "+974",
         "name": "Qatar"
       },
      //  {
      //    "code": "+262",
      //    "name": "Reunion"
      //  },
      //  {
      //    "code": "+40",
      //    "name": "Romania"
      //  },
       {
         "code": "+7",
         "name": "Russia"
       },
      //  {
      //    "code": "+250",
      //    "name": "Rwanda"
      //  },
      //  {
      //    "code": "+685",
      //    "name": "Samoa"
      //  },
      //  {
      //    "code": "+378",
      //    "name": "San Marino"
      //  },
       {
         "code": "+966",
         "name": "Saudi Arabia"
       },
      //  {
      //    "code": "+221",
      //    "name": "Senegal"
      //  },
      //  {
      //    "code": "+381",
      //    "name": "Serbia"
      //  },
      //  {
      //    "code": "+248",
      //    "name": "Seychelles"
      //  },
      //  {
      //    "code": "+232",
      //    "name": "Sierra Leone"
      //  },
       {
         "code": "+65",
         "name": "Singapore"
       },
      //  {
      //    "code": "+421",
      //    "name": "Slovakia"
      //  },
      //  {
      //    "code": "+386",
      //    "name": "Slovenia"
      //  },
      //  {
      //    "code": "+677",
      //    "name": "Solomon Islands"
      //  },
      //  {
      //    "code": "+27",
      //    "name": "South Africa"
      //  },
      //  {
      //    "code": "+500",
      //    "name": "South Georgia and the South Sandwich Islands"
      //  },
       {
         "code": "+82",
         "name": "South Korea"
       },
       {
         "code": "+34",
         "name": "Spain"
       },
      //  {
      //    "code": "+94",
      //    "name": "Sri Lanka"
      //  },
      //  {
      //    "code": "+249",
      //    "name": "Sudan"
      //  },
      //  {
      //    "code": "+597",
      //    "name": "Suriname"
      //  },
      //  {
      //    "code": "+268",
      //    "name": "Swaziland"
      //  },
       {
         "code": "+46",
         "name": "Sweden"
       },
       {
         "code": "+41",
         "name": "Switzerland"
       },
       {
         "code": "+963",
         "name": "Syria"
       },
       {
         "code": "+886",
         "name": "Taiwan"
       },
      //  {
      //    "code": "+992",
      //    "name": "Tajikistan"
      //  },
      //  {
      //    "code": "+255",
      //    "name": "Tanzania"
      //  },
       {
         "code": "+66",
         "name": "Thailand"
       },
      //  {
      //    "code": "+670",
      //    "name": "Timor Leste"
      //  },
      //  {
      //    "code": "+228",
      //    "name": "Togo"
      //  },
      //  {
      //    "code": "+690",
      //    "name": "Tokelau"
      //  },
      //  {
      //    "code": "+676",
      //    "name": "Tonga"
      //  },
      //  {
      //    "code": "+1 868",
      //    "name": "Trinidad and Tobago"
      //  },
      //  {
      //    "code": "+216",
      //    "name": "Tunisia"
      //  },
       {
         "code": "+90",
         "name": "Turkey"
       },
      //  {
      //    "code": "+993",
      //    "name": "Turkmenistan"
      //  },
      //  {
      //    "code": "+1 649",
      //    "name": "Turks and Caicos Islands"
      //  },
      //  {
      //    "code": "+688",
      //    "name": "Tuvalu"
      //  },
       {
         "code": "+1 340",
         "name": "U.S. Virgin Islands"
       },
      //  {
      //    "code": "+256",
      //    "name": "Uganda"
      //  },
      //  {
      //    "code": "+380",
      //    "name": "Ukraine"
      //  },
       {
         "code": "+971",
         "name": "United Arab Emirates"
       },
       {
         "code": "+44",
         "name": "United Kingdom"
       },
       {
         "code": "+1",
         "name": "United States"
       },
      //  {
      //    "code": "+598",
      //    "name": "Uruguay"
      //  },
      //  {
      //    "code": "+998",
      //    "name": "Uzbekistan"
      //  },
      //  {
      //    "code": "+678",
      //    "name": "Vanuatu"
      //  },
       {
         "code": "+58",
         "name": "Venezuela"
       },
       {
         "code": "+84",
         "name": "Vietnam"
       }
      //  {
      //    "code": "+1 808",
      //    "name": "Wake Island"
      //  },
      //  {
      //    "code": "+681",
      //    "name": "Wallis and Futuna"
      //  },
      //  {
      //    "code": "+967",
      //    "name": "Yemen"
      //  },
      //  {
      //    "code": "+260",
      //    "name": "Zambia"
      //  },
      //  {
      //    "code": "+255",
      //    "name": "Zanzibar"
      //  },
      //  {
      //    "code": "+263",
      //    "name": "Zimbabwe"
      //  }
     ];
   
 }
}
