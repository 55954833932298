import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormControlName } from '@angular/forms';
import { MatcoService } from '../../services/matco.service';
import { Router } from '@angular/router';
import { Country } from 'src/app/model/country.model';
import { Currency } from 'src/app/model/currency.model';

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  allFields: string[]= [];
  registerForm: FormGroup;
  countries: Country[];
  currencies: Currency[];
  submitted: boolean = false;
  phone_number;
  phone_country_code;
  send_status = "";

  constructor(private formBuilder: FormBuilder, 
              private matcoService: MatcoService,
              private router: Router) { 
              }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.send_status = "";
    this.phone_country_code = this.matcoService.loadCountryCode();
    
    this.registerForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      companyName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
      ]),

      
      email2: new FormControl(''),

      companyAddress1: new FormControl('', Validators.required),
      companyAddress2: new FormControl('', Validators.required),
      country: new FormControl('Singapore'),
      currency: new FormControl('SGD'),
      postalCode: new FormControl('', [
        Validators.required
      //  Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),

      phone_country_code: new FormControl('+65'),

      telephoneNo: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),

      cellphone_country_code: new FormControl('+65'),


      mobileNo: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),
      displayUsername: new FormControl('', [Validators.required, Validators.minLength(2)]),
      password: new FormControl('', [
        Validators.required, 
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]),
      confirmPassword: new FormControl(''),
    });

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.matcoService.getAllCurrencies()
      .subscribe(
        (currencies: Currency[]) => {
          this.currencies = currencies;
        }
      );
    this.getAllFields();
  }

  registerCustomer() {
    if(this.registerForm.invalid) {
      for (let i = 0; i < this.allFields.length; i ++) {
        if (this.registerForm.get(this.allFields[i]).invalid || this.registerForm.get(this.allFields[i]).value === "") {
          (<any>this.registerForm.get(this.allFields[i])).nativeElement.focus();
          break;
        }
      }
      return;
    } 
    
    this.send_status = "sending";
    this.matcoService.registerCustomer(this.registerForm.value).subscribe(data => {
      this.send_status = "";
      this.registerForm.reset();
      this.submitted = true;
      window.scroll(0,0);
      setTimeout(()=>this.router.navigate(['/']),2000);
    },error => { 
      //  console.log(error)
    });
  }

  getAllFields () {
    Object.keys(this.registerForm.controls).forEach((key) => {
      return this.allFields.push(key);
    });
  }

  get form() {
    return this.registerForm.controls;
  }

  isFieldRequired(field: string) {
    return this.registerForm.get(field).errors.required;
  }

  isFieldRequiredMinLength(field: string) {
    return this.registerForm.get(field).errors.minlength;
  }

  isFieldRequiredPattern(field: string) {
    return this.registerForm.get(field).errors.pattern;
  }

  isFieldvalid(field: string) {
    return this.registerForm.get(field).invalid && (this.registerForm.get(field).dirty || this.registerForm.get(field).touched)
  }

  isEqual(){
    return this.registerForm.get('email').value == this.registerForm.get('email2').value;
  }
    



}
