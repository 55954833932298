import { Component, OnInit } from '@angular/core';
import { FormControlName, FormGroup, FormControl, FormBuilder,Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';
import { Router } from '@angular/router';
import { Country } from 'src/app/model/country.model';

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

@Component({
  selector: 'app-shipping-details',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.css']
})
export class ShippingDetailsComponent implements OnInit {

  allFields: string[]= [];
  shippingForm: FormGroup;
  phone_country_code;
  countries: Country[];
  userNo: string;
  orderNo: string;
  email : string;
  userDetails:any;
  enable: boolean = false;
  send_status = "";

  constructor(private formBuilder: FormBuilder, 
              private matcoService: MatcoService,
              private router: Router) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
    this.email = JSON.parse(localStorage.getItem('user')).email;
    this.userDetails = JSON.parse(localStorage.getItem('user'));
    this.enable = this.userDetails.country == 'Singapore' ? false : true;
    this.phone_country_code = this.matcoService.loadCountryCode();
    
    this.shippingForm = this.formBuilder.group({
      companyName: new FormControl(this.userDetails.companyName, [Validators.required, Validators.minLength(3)]),
      contactName: new FormControl(this.userDetails.displayUserName, [Validators.required, Validators.minLength(3)]),
      cellphone_country_code: new FormControl({value : this.getCountryCode(this.userDetails.mobileNo, this.userDetails.mobileNoCode, this.userDetails.country), disabled : this.enable}),

      mobileNumber: new FormControl(this.getNumber(this.userDetails.mobileNo, this.userDetails.mobileNoCode), [
        Validators.required,
        Validators.pattern(/^[1-9]+[0-9]*$/)
      ]),
      email: new FormControl(this.userDetails.email, [
        Validators.required,
        Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
      ]),

      companyAddress1: new FormControl(this.userDetails.address1, Validators.required),
      companyAddress2: new FormControl(this.userDetails.address2),
      country: new FormControl({value: this.userDetails.country, disabled : this.enable}),
      
      postalCode: new FormControl(this.userDetails.postalCode, [
       Validators.required
      ]),

      remarks: new FormControl('')
    });

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );

     this.matcoService.getUserOrderByEmail(this.email).subscribe(data => {
       this.orderNo = data[0].order[0].docNum;
     })

     this.getAllFields();
  }


  getAllFields () {
    Object.keys(this.shippingForm.controls).forEach((key) => {
      return this.allFields.push(key);
    });
  }

  get form() {
    return this.shippingForm.controls;
  }

  isFieldRequired(field: string) {
    return this.shippingForm.get(field).errors.required;
  }

  isFieldRequiredMinLength(field: string) {
    return this.shippingForm.get(field).errors.minlength;
  }

  isFieldRequiredPattern(field: string) {
    return this.shippingForm.get(field).errors.pattern;
  }

  isFieldvalid(field: string) {
    return this.shippingForm.get(field).invalid && (this.shippingForm.get(field).dirty || this.shippingForm.get(field).touched)
  }

  updateShippingDetails() {
    // console.log(this.shippingForm.invalid)
    if(this.shippingForm.invalid) {
      for (let i = 0; i < this.allFields.length; i ++) {
        if (this.shippingForm.get(this.allFields[i]).invalid || this.shippingForm.get(this.allFields[i]).value === "") {
          (<any>this.shippingForm.get(this.allFields[i])).nativeElement.focus();
          break;
        }
      }
      return;
    } 

    this.send_status = 'sending';
    this.matcoService.saveShippingDetails(this.shippingForm.value, this.userNo, this.orderNo, this.userDetails.country)
    .subscribe(data => {
      this.send_status = '';
      this.router.navigate(['/payment']);
    })
  }

  getCountryCode(phone, code, countryProfile) {

    if(phone) {
      // console.log(code)
      return code;
    } else {
      this.matcoService.loadCountryCode().forEach(country => {
        if(countryProfile == country.name) {
          // console.log(country.code)
          return country.code;
        }
      })
    }
  }

  getNumber(phone, code) {
    if(phone) {
      // console.log(phone.substring(code.length,phone.length))
      return phone.substring(code.length,phone.length);
    } else {
      return '';
    }
  }
}
