import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';

import { HeaderComponent } from './partial/header/header.component';
import { FooterComponent } from './partial/footer/footer.component';
import { CartComponent } from './pages/cart/cart.component';
import { RegisterComponent } from './pages/register/register.component';

import { ProductsComponent } from './pages/products/products.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';


import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SearchComponent } from './pages/search/search.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminInquiriesComponent } from './pages/admin-inquiries/admin-inquiries.component';
import { AdminCustomersComponent } from './pages/admin-customers/admin-customers.component';
import { AdminManageInquiryComponent } from './pages/admin-manage-inquiry/admin-manage-inquiry.component';
import { TermsAndConditionComponent } from './pages/terms-and-condition/terms-and-condition.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

import { AdminManageCustomerComponent } from './pages/admin-manage-customer/admin-manage-customer.component';

import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EmailValidationComponent } from './pages/email-validation/email-validation.component';
import { AdminUsersComponent } from './pages/admin-users/admin-users.component';
import { AdminManageUserComponent } from './pages/admin-manage-user/admin-manage-user.component';
import { AdminCreateUserComponent } from './pages/admin-create-user/admin-create-user.component';
import { FilterPipe } from './pipe/filter.pipe';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { OrderComponent } from './pages/order/order.component';
import { ShippingDetailsComponent } from './pages/shipping-details/shipping-details.component';
import { AdminOrdersComponent } from './pages/admin-orders/admin-orders.component';
import { AdminManageOrderComponent } from './pages/admin-manage-order/admin-manage-order.component';
import { DashboardOrderComponent } from './pages/dashboard-order/dashboard-order.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { AdminViewOrderComponent } from './pages/admin-view-order/admin-view-order.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FullPaymentComponent } from './pages/full-payment/full-payment.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    RegisterComponent,
    AdminManageCustomerComponent,
    ProductsComponent,
    CheckoutComponent,
    ProductDetailComponent,
    SearchComponent,
    DashboardComponent,
    ProfileComponent,
    AdminInquiriesComponent,
    AdminCustomersComponent,
    AdminManageInquiryComponent,
    TermsAndConditionComponent,
    ContactUsComponent,
    ChangePasswordComponent,
    EmailValidationComponent,
    AdminUsersComponent,
    AdminManageUserComponent,
    AdminCreateUserComponent,
    FilterPipe,
    InquiryComponent,
    OrderComponent,
    ShippingDetailsComponent,
    AdminOrdersComponent,
    AdminManageOrderComponent,
    DashboardOrderComponent,
    PaymentComponent,
    AdminViewOrderComponent,
    ForgotPasswordComponent,
    FullPaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
