import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { Product } from 'src/app/model/product.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  partNo: string;
  products: Product[];
  searchForm: FormGroup;
  manufacturer:string;
  partno:string;

  constructor(private matcoService: MatcoService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,) { 
                
                this.router.events.subscribe((event: Event) => {
                
      
                  if (event instanceof NavigationEnd) {
                    this.search();
                  }
      
                 
              });

              }

  ngOnInit() {
  
   
  }
  
  transform(value: string, regex: string, replaceValue: string): any {
    return value.replace(regex, replaceValue);
  }

  search() {
   this.manufacturer = this.route.snapshot.paramMap.get('manufacturer');
   this.partno = this.route.snapshot.paramMap.get('search');
   

    if(!this.partno){
      this.partno = "";
    }

    if(!this.manufacturer){
      this.manufacturer = "all";
    }
  
    
    this.matcoService.getProductsByPartAndBrand(this.manufacturer,this.partno)
    .subscribe(
      (products: Product[]) => {
        this.products = products;
      }
    );
    
    
  } 



}
