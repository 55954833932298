import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  allFields: string[]= [];
  forgotPasswordForm: FormGroup;
  submitted: boolean = false;
  send_status = "";
  code;

  constructor(private formBuilder: FormBuilder, 
              private matcoService: MatcoService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    const firstParam: string = this.route.snapshot.queryParamMap.get('email');
    this.code = this.route.snapshot.queryParamMap.get('code');

    this.matcoService.updateCartEmitter("addProduct");
    this.send_status = "";
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl(firstParam, [
        Validators.required,
        Validators.pattern(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}|.[a-z]{2,3}$/)
      ]),
      password: new FormControl('', [
        Validators.required, 
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]),
      confirmPassword: new FormControl(''),
      // code: new FormControl(secondParam),
    });

  }

  getAllFields () {
    Object.keys(this.forgotPasswordForm.controls).forEach((key) => {
      return this.allFields.push(key);
    });
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }

  isFieldRequired(field: string) {
    return this.forgotPasswordForm.get(field).errors.required;
  }

  isFieldRequiredMinLength(field: string) {
    return this.forgotPasswordForm.get(field).errors.minlength;
  }

  isFieldRequiredPattern(field: string) {
    return this.forgotPasswordForm.get(field).errors.pattern;
  }

  isFieldvalid(field: string) {
    return this.forgotPasswordForm.get(field).invalid && (this.forgotPasswordForm.get(field).dirty || this.forgotPasswordForm.get(field).touched)
  }

  isEqual(){
    return this.forgotPasswordForm.get('email').value == this.forgotPasswordForm.get('email2').value;
  }

  submit() {
    // console.log(this.forgotPasswordForm.value);
    this.send_status = 'sending';
    this.matcoService.resetPassword(this.forgotPasswordForm.value, this.code).subscribe( data => {
      this.send_status = '';
      // console.log(data)
      this.forgotPasswordForm.reset();
      window.scroll(0,0);
    });
  }
}
