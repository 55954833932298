import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { FormGroup, FormArray, FormControl, FormBuilder  } from '@angular/forms';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {

  constructor(
    private matcoService: MatcoService,
    private fb: FormBuilder
  ) { }
  
  products:any;
  additionalService:any;
  form: FormGroup;
  remarks: FormControl;
  userId: FormControl;
  authenticated: boolean = false;
  user_token: string;
  sent: boolean = false;
  send_status = "";
  inqErrorMsg = "";

  ngOnInit() {

    if(localStorage.getItem('user')){
      this.user_token = JSON.parse(localStorage.getItem('user')).userNo; 
      this.authenticated = this.matcoService.checkIfIsLogged();
      // this.matcoService.logged.subscribe(data =>{
      //   this.authenticated = data;
      //   console.log(this.authenticated);
      // });
    }


    this.form = this.fb.group({
        secretProducts: this.fb.array([]),
        additionalServices: this.fb.array([]),
        remarks: new FormControl(''),
        userNo: new FormControl(this.user_token)
    });
    this.loadProducts();
    this.loadAdditionalService();


        
    
    
  }

  
  get secretProducts(): FormArray{
    return this.form.get('secretProducts') as FormArray;
  }

  get additionalServices(): FormArray{
    return this.form.get('additionalServices') as FormArray;
  }

  checkBoxAdditional:any;
  loadProducts(){
    this.products = JSON.parse(localStorage.getItem("productsInCart"));
    for(var product in this.products){
      this.secretProducts.push(
      this.fb.group(this.products[product]) 
      );
      // console.log(this.products[product]);
    }
   


  }
  remarkDetails: any;
  
  

  loadAdditionalService(){
    this.matcoService.getAdditionalRemarks().subscribe(data => {
      // console.log(data)
      this.additionalService = data[0];
      this.remarkDetails = data[0].remarkDetails;
      
      for(var rmk in this.remarkDetails){
       this.additionalServices.push(
          this.fb.group(this.remarkDetails[rmk])
        );
      }
     
    });
  }


  deleteProductInCart(property: string){
    
    
    delete this.products[property];
    localStorage.setItem("productsInCart",JSON.stringify(this.products));
     this.products = JSON.parse(localStorage.getItem("productsInCart"));
   // this.snackBarMsg = "Product removed from cart";
    this.matcoService.updateCartEmitter("deletecart");
    this.ngOnInit();
    
  }



  sendEnquiry(){
    if(this.user_token){
    this.send_status = 'sending';
    var inquiry = JSON.stringify(this.form.value);
    // console.log(inquiry)
    this.matcoService.sendInquiry2(inquiry).subscribe(data => {
      // console.log(data)
      this.send_status = '';
      this.sent = true;
      localStorage.setItem("productsInCart", "{}");
      this.matcoService.updateCartEmitter("deletecart");
      this.ngOnInit();
    }, error => {
      this.send_status = '';
      this.inqErrorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
    });
    
  }else{
   
    alert("Please Login");
  }

    
  }


}
