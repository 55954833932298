import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CartComponent } from './pages/cart/cart.component';
import { RegisterComponent } from './pages/register/register.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';

import { ProductsComponent } from './pages/products/products.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { SearchComponent } from './pages/search/search.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { AdminCustomersComponent } from './pages/admin-customers/admin-customers.component';
import { AdminInquiriesComponent } from './pages/admin-inquiries/admin-inquiries.component';
import { AdminManageInquiryComponent } from './pages/admin-manage-inquiry/admin-manage-inquiry.component';
import { TermsAndConditionComponent } from './pages/terms-and-condition/terms-and-condition.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AdminManageCustomerComponent } from './pages/admin-manage-customer/admin-manage-customer.component';
import { EmailValidationComponent } from './pages/email-validation/email-validation.component';
import { AdminUsersComponent } from './pages/admin-users/admin-users.component';
import { AdminManageUserComponent } from './pages/admin-manage-user/admin-manage-user.component';
import { AdminCreateUserComponent } from './pages/admin-create-user/admin-create-user.component';
import { ShippingDetailsComponent } from './pages/shipping-details/shipping-details.component';
import { AdminOrdersComponent } from './pages/admin-orders/admin-orders.component';
import { OrderComponent } from './pages/order/order.component';
import { DashboardOrderComponent } from './pages/dashboard-order/dashboard-order.component';
import { AdminManageOrderComponent } from './pages/admin-manage-order/admin-manage-order.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { AdminViewOrderComponent } from './pages/admin-view-order/admin-view-order.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FullPaymentComponent } from './pages/full-payment/full-payment.component';

const routes: Routes = [
  
  { path: '', component: HomeComponent},
  { path: 'cart', component: CartComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'search/:manufacturer', component:SearchComponent },
  { path: 'search/:manufacturer/:search', component:SearchComponent },
  { path: 'dashboard', component:DashboardComponent},
  { path: 'dashboard-order', component:DashboardOrderComponent},
  { path: 'profile', component:ProfileComponent},
  { path: 'change-password', component:ChangePasswordComponent},
  { path: 'terms-and-condition', component:TermsAndConditionComponent},
  { path: 'contact-us', component:ContactUsComponent},

  { path: 'products/:brand', component: ProductsComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'product-detail/:id', component: ProductDetailComponent },

  { path: 'admin-users', component: AdminUsersComponent },
  { path: 'admin-create-user', component: AdminCreateUserComponent },
  { path: 'admin-manage-user/:email', component: AdminManageUserComponent },
  { path: 'admin-customers', component: AdminCustomersComponent },
  { path: 'admin-inquiries', component: AdminInquiriesComponent },
  { path: 'admin-manage-inquiry/:id', component: AdminManageInquiryComponent },
  { path: 'admin-manage-customer/:email', component: AdminManageCustomerComponent },
  { path: 'admin-orders', component: AdminOrdersComponent },
  { path: 'admin-manage-order/:id', component: AdminManageOrderComponent },
  { path: 'view-order/:id', component: AdminViewOrderComponent },

  { path: 'email-validation', component: EmailValidationComponent },

  { path: 'shipping-details', component: ShippingDetailsComponent },
  { path: 'orders', component: OrderComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'full-payment/:id', component: FullPaymentComponent },
  { path: 'inquiry', component: InquiryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
