import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatcoService } from 'src/app/services/matco.service';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('closeLoginModal') closeLoginModal: ElementRef;
  products: Product[];
  user: User;
  authenticated: boolean = false;
  loginForm: FormGroup;
  searchForm: FormGroup;
  forgotPassForm : FormGroup;
  errorMsg: string = "";
  submitted = false;
  myName: string = "";
  forgotPassword: boolean = false;
  successMsg: string = "";
  email: string = "";
  clickIn = false;
  showOnce = false;
  login_status = "";

  constructor(private formBuilder: FormBuilder,
              private matcoService: MatcoService,
              private router: Router) { }

  cart_size: number;
  logged = false;
  userNo: string;
  currency;
  avail;
  sapCardCode;
  forgot_status= "";



  
  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.login_status = "";
    this.matcoService.checkIfIsLogged();
    this.initForm();
    this.getMenuBrands();
    this.authenticated = this.matcoService.checkIfIsLogged();

    this.matcoService.logged.subscribe(data =>{
        this.authenticated = data;
    });
    
    this.matcoService.cartEmitter.subscribe(action=>{
      if(localStorage.getItem("productsInOrder")){
        this.checkCartSize();
      }else{
        localStorage.setItem("productsInCart","{}")
        localStorage.setItem("productsInOrder","{}")
        localStorage.setItem("secret", "false");
      }
     
    })

    // if(this.authenticated) {
    //   this.matcoService.getUserOrderByEmail(this.email).subscribe(data => {
    //     console.log(data[0].order)
    //   }) 
    // }

    this.searchForm = this.formBuilder.group({
      manufacturer: new FormControl('all',Validators.required),
      partNo: new FormControl()
    });
    
    if(localStorage.getItem('user')){
      this.myName = JSON.parse(localStorage.getItem('user')).displayUserName;
      this.userRole = JSON.parse(localStorage.getItem('user')).role;
      }
    // this.myName = JSON.parse(localStorage.getItem('user')).displayUserName;
 }

 ngAfterViewInit(): void {
   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
   //Add 'implements AfterViewInit' to the class.
   this.searchForm.value.manufacturer = 'all';

   if(localStorage.getItem('user')){
    this.myName = JSON.parse(localStorage.getItem('user')).displayUserName;
    this.userRole = JSON.parse(localStorage.getItem('user')).role;
    }


 }


 checkCartSize(){
  var objectCart = Object.keys(JSON.parse(localStorage.getItem("productsInOrder")));
  this.cart_size = objectCart.length;
 }


 brands:any = null;
 userRole:any = null;

 getMenuBrands(){
  this.matcoService.getBrand().subscribe(
    data => { 
      this.brands = data;
    },
    error => { console.log(error) }
  )
 }


 private initForm() {

  this.forgotPassForm = this.formBuilder.group({
    email: new FormControl(),
  }); 
  
  this.loginForm = this.formBuilder.group({
    email: new FormControl(),
    password: new FormControl()
  });

  this.errorMsg = null;
  this.forgotPassword = false;
 }

 login() {
   this.login_status = "sending";
   this.submitted = true;
   this.email = this.loginForm.get('email').value;
   this.matcoService.login(this.loginForm.value);
   this.user = JSON.parse(localStorage.getItem('user'));
   this.userRole = '';

   this.matcoService.logged.subscribe(data => {
    if(data == true){
      this.login_status = "";
      this.closeLoginModal.nativeElement.click();
      this.errorMsg = null;
        if(localStorage.getItem('user')){
          this.myName = JSON.parse(localStorage.getItem('user')).displayUserName;
          this.userRole = JSON.parse(localStorage.getItem('user')).role;
          this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
          this.sapCardCode = JSON.parse(localStorage.getItem('user')).sapCardCode;
          this.initForm();
        }

      this.matcoService.getUserOrderByEmail(this.email).subscribe(data => {
        // console.log(data[0].order[0])
        if(data[0].order.length > 0) {
          localStorage.setItem("secret", "true-"+data[0].order[0].docNum);
         

          for(let product of data[0].order[0].details) {
            // console.log(product.itemCode)

            if(product.itemCode.includes('S')) {

              this.matcoService.getSubitemDetails(product.itemCode,this.sapCardCode).subscribe(data => {
                var currentOrders = JSON.parse(localStorage.getItem("productsInOrder"));
                // console.log(data[0])
                this.currency = data[0].currency;
                this.avail = data[0].available;
                // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
                // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;
  
                currentOrders[product.itemCode] = product;
                currentOrders[product.itemCode].currency = this.currency;
                currentOrders[product.itemCode].available = this.avail;
                // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
                // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;
  
                // console.log(currentOrders)
                localStorage.setItem("productsInOrder", JSON.stringify(currentOrders));
                this.matcoService.updateCartEmitter("addProduct");
              })

            } else {
              this.matcoService.getProductDetails(product.itemCode).subscribe(data =>{
                var currentOrders = JSON.parse(localStorage.getItem("productsInOrder"));
                // console.log(data[0])
                this.currency = data[0].currency;
                this.avail = data[0].available;
                // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
                // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;
  
                currentOrders[product.itemCode] = product;
                currentOrders[product.itemCode].currency = this.currency;
                currentOrders[product.itemCode].available = this.avail;
                // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
                // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;
  
                // console.log(currentOrders)
                localStorage.setItem("productsInOrder", JSON.stringify(currentOrders));
                this.matcoService.updateCartEmitter("addProduct");
              })
            }
            
            
          }
         
         
        } else {
          localStorage.setItem("secret", "false");
        }
        // this.router.navigate(['']);
      }) 

      }else{
        this.login_status = "";
        localStorage.setItem("secret", "false");
        this.errorMsg = "Invalid Credentials or your account is not yet validated.";
      }
      
   });
}
  
falseSubmitted(){
  this.submitted = false;
  this.initForm();
}

 logout() {
  this.errorMsg = null;
  this.matcoService.logout();
  this.matcoService.checkIfIsLogged();
  this.submitted = false;
  this.authenticated = this.matcoService.checkIfIsLogged();
  localStorage.setItem("productsInCart","{}")
  localStorage.setItem("productsInOrder","{}")
  localStorage.setItem("secret", "false");
  this.matcoService.updateCartEmitter("addProduct");
 }
  
 search(){
    var searchformValuePartNo =  this.searchForm.value.partNo;
    var searchformValueManufacturer =  this.searchForm.value.manufacturer;
    if(searchformValuePartNo){
      this.router.navigate(['/search',searchformValueManufacturer,searchformValuePartNo]);
    }else{
      this.router.navigate(['/search',searchformValueManufacturer]);
    }
   
 }

 gotoCart(){
  //this.router.navigate(['/search',searchformValueManufacturer]);
  this.router.navigate(['/cart'], { skipLocationChange: true });
  
 }

 clear() {
   this.errorMsg = "";
 }

 goToRegistration() {
  this.closeLoginModal.nativeElement.click();
  this.router.navigate(['/register'], { skipLocationChange: true });
 }

 forgotPass() {
   this.forgotPassword = true;
  // this.closeLoginModal.nativeElement.click();
  // this.router.navigate(['/forgot-password']);
 }

 loadVal(id, paramPartNo){
   this.clickIn = true;
   this.router.navigate(['/product-detail',id]);
    this.searchForm.setValue(
        { partNo : paramPartNo , manufacturer: this.searchForm.value.manufacturer}
    );
    this.products = [];
    setTimeout(()=> this.clickIn = false, 190);
 }
 clearProduct(){
  //this.products = [];
 }

 showProductsByCategory($event) {
  this.showOnce = true;
  var searchformValuePartNo =  this.searchForm.value.partNo;
  var searchformValueManufacturer =  this.searchForm.value.manufacturer;
  this.matcoService.getProductsByPartAndBrand2(searchformValueManufacturer, searchformValuePartNo)
  .subscribe(
    (products: Product[]) => {
      this.products = products;
      //console.log(products);
    }
  );
}
 
 sendPasswordToEmail() {
   this.forgot_status = 'sending';
   this.matcoService.sendPasswordtoEmail(this.forgotPassForm.value)
   .subscribe(
     (data) => {
       //console.log(data)
       this.forgot_status = '';
       this.submitted = true;
       this.successMsg = "Password has been successfully sent your email."
     }
   );
  
 }

 resendEmail() {
  this.closeLoginModal.nativeElement.click();
  this.matcoService.resendEmailValidation(this.email);
  // .subscribe(data => {
  //   console.log(data)
  // });
 }

 focusout() {
  setTimeout(()=> {
    this.showOnce = false;
  }, 150);
  
 }
}

