import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  checkoutForm = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    companyName: [''],
    email: [''],
    address: [''],
    country: [''],
    postalCode: [''],
    phone: [''],
    username: [''],
    password: [''],
    password2: [''],
  });



  ngOnInit() {
  }

}
