import { Component, OnInit, ViewChild } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product } from 'src/app/model/product.model';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {



  itemCode: string;
  product: Product;
  addComp: Product;
  availableQty: number[];
  qty = new FormControl('');
  userRole;
  snackBarMsg: string;
  sapCardCode: string;
  subitemList;
  desc: string;
  userNo;

  constructor(private matcoService: MatcoService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    
    this.route.params
    .subscribe(
      (params: Params) => {
        this.itemCode = params['id'];

          if(localStorage.getItem('user')) {
            this.sapCardCode = JSON.parse(localStorage.getItem('user')).sapCardCode;
            this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
            // console.log(this.sapCardCode)
            this.matcoService.getProductDetailsSapCardCode(this.itemCode,this.sapCardCode).subscribe(
              (product:Product) => {
                this.product = product[0];
                 console.log(this.product)
                this.availableQty = Array(99).fill(0).map((x,i)=>i);
                this.qty.setValue(1);

                this.matcoService.getSubitemList().subscribe(data => {
                  this.subitemList=data;
                  
                  // console.log(this.product)
                  // console.log(data)
                  this.subitemList.forEach(element => {
                    if(element.manufacturer == this.product.manufacturer) {
                      this.desc = element.masterItemCode;
                      // console.log(this.desc)
                    }
                  });
                })
              }
            )
          } else {
            this.matcoService.getProductDetails(this.itemCode)
            .subscribe(
              (product: Product) => {
                this.product = product[0];
                this.availableQty = Array(99).fill(0).map((x,i)=>i);
                this.qty.setValue(1);
                // console.log(this.product)

                this.matcoService.getSubitemList().subscribe(data => {
                  this.subitemList = data;
                  // console.log(this.product)
                  // console.log(data)

                  this.subitemList.forEach(element => {
                    if(element.manufacturer == this.product.manufacturer) {
                      this.desc = element.masterItemCode;
                    }
                  });
                })
              }
            );
          }
      }
    );


  if(localStorage.getItem('user')){
      this.userRole = JSON.parse(localStorage.getItem('user')).role;
    }

    // this.matcoService.getSubitemList().subscribe(data => {
    //   this.subitemList = data;
    //   console.log(data)
    // })

  }

  addComponent(event) {

    if(this.product.manufacturer == 'Bettis') {
      // var checkBoxes = document.getElementsByName('subItem');
      var checkBoxes = [];
      checkBoxes.push(document.querySelectorAll('[data-item]'))
      // console.log(checkBoxes[0]);
      checkBoxes[0].forEach(element => {
        
        (<HTMLInputElement>element).checked = true;
        if((<HTMLInputElement>element).checked) {
        // console.log((<HTMLInputElement>element).value)
        this.matcoService.getSubitemDetails((<HTMLInputElement>element).value, this.sapCardCode).subscribe((data: Product) => {
          // console.log(data[0]);
          this.addComp = data[0];    
  
  
          var currentproduct = JSON.parse(localStorage.getItem("productsInOrder"));
            
            currentproduct[this.addComp.itemCode] = this.addComp;
            currentproduct[this.addComp.itemCode].quantity = 1;
            currentproduct[this.addComp.itemCode].price = this.addComp.price;
            currentproduct[this.addComp.itemCode].remarks = '';
            currentproduct[this.addComp.itemCode].lineTotal = '';
            currentproduct[this.addComp.itemCode].available = this.addComp.available;
            currentproduct[this.addComp.itemCode].currency = this.addComp.currency;
  
            localStorage.setItem("productsInOrder", JSON.stringify(currentproduct));
        }) 
      } 
      else {
        var currentproduct = JSON.parse(localStorage.getItem("productsInOrder"));
        delete currentproduct[event.target.value];
        localStorage.setItem("productsInOrder",JSON.stringify(currentproduct));
      }
      })
    } else {
      if(event.target.checked) {
        this.matcoService.getSubitemDetails(event.target.value, this.sapCardCode).subscribe((data: Product) => {
          // console.log(data[0]);
          this.addComp = data[0];    
  
  
          var currentproduct = JSON.parse(localStorage.getItem("productsInOrder"));
            
            currentproduct[this.addComp.itemCode] = this.addComp;
            currentproduct[this.addComp.itemCode].quantity = this.qty.value;
            currentproduct[this.addComp.itemCode].price = this.addComp.price;
            currentproduct[this.addComp.itemCode].remarks = '';
            currentproduct[this.addComp.itemCode].lineTotal = '';
            currentproduct[this.addComp.itemCode].available = this.addComp.available;
            currentproduct[this.addComp.itemCode].currency = this.addComp.currency;
  
            localStorage.setItem("productsInOrder", JSON.stringify(currentproduct));
        }) 
      } 
      else {
        var currentproduct = JSON.parse(localStorage.getItem("productsInOrder"));
        delete currentproduct[event.target.value];
        localStorage.setItem("productsInOrder",JSON.stringify(currentproduct));
      }
    }
    
    
  }

  addProductToCart(product: Product) {
    var currentproduct = JSON.parse(localStorage.getItem("productsInCart"));
     
    
    currentproduct[product.itemCode] = product;
    currentproduct[product.itemCode].quantity = this.qty.value;
    currentproduct[product.itemCode].remarks = '';
    
    localStorage.setItem("productsInCart", JSON.stringify(currentproduct));
    //console.log(this.qty);

    this.matcoService.updateCartEmitter("addProduct");

    this.router.navigate(['/inquiry'], { replaceUrl: true });
  }


  addProductToOrder(product: Product) {
    var currentproduct = JSON.parse(localStorage.getItem("productsInOrder"));
     
    
    currentproduct[product.itemCode] = product;
    currentproduct[product.itemCode].quantity = this.qty.value;
    currentproduct[product.itemCode].price = product.price;
    currentproduct[product.itemCode].remarks = '';
    currentproduct[product.itemCode].lineTotal = '';
    currentproduct[product.itemCode].available = product.available;
    currentproduct[product.itemCode].currency = product.currency;

    localStorage.setItem("productsInOrder", JSON.stringify(currentproduct));

    
    let arr = [];

    currentproduct = JSON.parse(localStorage.getItem("productsInOrder"))
    // console.log(currentproduct)
    for(var prod in JSON.parse(localStorage.getItem("productsInOrder"))){
      // console.log(sample);
      arr.push(currentproduct[prod])
    }
    
    // console.log(arr)

    
    const body = {
      "secretProducts" : arr,
    }

    // console.log(body);
    this.matcoService.addToCartDB(JSON.stringify(body), this.userNo);
    // .subscribe(data => {
    //   console.log(data)
    // });
    
    this.matcoService.updateCartEmitter("addProduct");

    this.router.navigate(['/orders'], { replaceUrl: true });
  }

  transform(value: string, regex: string, replaceValue: string): any {
    return value.replace(regex, replaceValue);
  }
}
