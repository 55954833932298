import { Component, OnInit } from '@angular/core';
import { MatcoService } from '../../services/matco.service';
import { Router } from '@angular/router';
import { Inquiry } from 'src/app/model/inquiry.model';
import { InquiryDetails } from 'src/app/model/inquiryDetails.model';
import { stringify } from 'querystring';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  inquiries = null;
  inquiryDetails: InquiryDetails;


  containerInquiries : Object;



  constructor(private matcoService: MatcoService, private router : Router ) { }
  auth:Boolean = false;
  ngOnInit() {

    
    var email: string = "";
    if(localStorage.getItem('user')){
      email = JSON.parse(localStorage.getItem('user')).email; 
    }

    this.auth = this.matcoService.checkIfIsLogged();
    if(this.auth == false){
          this.router.navigate(['/']);
        }
    // this.matcoService.logged.subscribe(data=> {
    //   this.auth = data;
    //   if(this.auth == false){
    //     this.router.navigate(['/']);
    //   }
      
    // });

    
    this.matcoService.getAllInquiriesByCustomerEmail(email).subscribe(data => {
      if(data){
        // console.log(data[0].inquiries);
       this.inquiries = data[0].inquiries;
      //  console.log(data);
      }
    })

    /*this.matcoService.getAllInquiriesByCustomerToken(user_token)
      .subscribe(
        (inquiries: Inquiry[]) => {
          if(this.inquiries){

            this.inquiries = inquiries;
            for(var i in this.inquiries){
                this.containerInquiries['orders'] = this.getInquiryDetails(inquiries[i].DocNum);
            }
            
            console.log(this.containerInquiries);
          }
         
        }
      ); */
  }

  getInquiryDetails(DocEntry: string) {
    this.inquiryDetails = null;
    var user_token: string = "";
    if(localStorage.getItem('user')){
      user_token = localStorage.getItem('token'); 
    }

    this.matcoService.getInquiryDetail(user_token, DocEntry)
      .subscribe(
        (inquiryDetails: InquiryDetails) => {
          this.inquiryDetails = inquiryDetails;
          return  this.inquiryDetails;
        }
      );
     
  }

}
