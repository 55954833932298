import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-inquiries',
  templateUrl: './admin-inquiries.component.html',
  styleUrls: ['./admin-inquiries.component.css']
})
export class AdminInquiriesComponent implements OnInit {

  constructor(
    private matcoService: MatcoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  inquiries = null;
  //searchForm: FormGroup;
  searchText = "";


  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.matcoService.getAllAdminInquiries().subscribe(data => {
      if(data){
        // console.log(data[0].inquiries)
        this.inquiries = data[0].inquiries;
        //console.log(this.inquiries);
       }
    });
  }

}
