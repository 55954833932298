import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';

@Component({
  selector: 'app-dashboard-order',
  templateUrl: './dashboard-order.component.html',
  styleUrls: ['./dashboard-order.component.css']
})
export class DashboardOrderComponent implements OnInit {

  constructor(private matcoService: MatcoService) { }

  orders = null;

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    var email: string = "";
    if(localStorage.getItem('user')){
      email = JSON.parse(localStorage.getItem('user')).email; 
    }

    this.matcoService.getAllOrdersPerUser(email).subscribe(data => {
      // console.log(data[0].order[0])

      if(data) {
          this.orders = data[0].order;
        // console.log(this.orders[0].details)
        this.orders[0].details.forEach(element => {
          element.price =  parseFloat(element.price).toFixed(2);
          element.lineTotal = parseFloat(element.lineTotal).toFixed(2);
        });
      }
      
     
     
    })
  }

}
