import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-manage-inquiry',
  templateUrl: './admin-manage-inquiry.component.html',
  styleUrls: ['./admin-manage-inquiry.component.css']
})
export class AdminManageInquiryComponent implements OnInit {
  
  constructor(
    private matcoService: MatcoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  
  cardCode = new FormControl('');
  inquiryID = null;
  inquiries = null;
  details   = null;
  companies = null;
  sapCardCode = null;
  btn_label = "Create Quotation";
  send_status_quotation = "";
  send_email_status = "";
  userNo = "";
  errorQuotationMsg = "";
  errorSendEmailMsg = "";
  
  ngOnInit() {
    this.send_status_quotation = "";
    this.send_email_status = "";
    this.userNo = JSON.parse(localStorage.getItem('user')).userNo;
    this.route.params
    .subscribe(
      (params: Params) => {
        this.inquiryID = params['id'];
        this.matcoService.getInquiriesByID(this.inquiryID).subscribe(data => {
          // console.log(data[0].inquiries)
           this.inquiries = data[0].inquiries; 
           this.details = this.inquiries[0].details; 
           this.sapCardCode = this.inquiries[0].sapCardCode;

              this.matcoService.getAllCompanies(this.inquiries[0].companyName, this.inquiries[0].currency).subscribe( companies => {
                this.companies = companies;
                this.cardCode.setValue(this.sapCardCode);
              })
           
        });
      }
    );
  }


  changeCardCode($cardCode, cardName, priceList, currency){

   var x = confirm("Are you sure you want to select this card code :" + $cardCode );
   if(x == true){
      var userNo = JSON.parse(localStorage.getItem('user')).userNo;
      this.matcoService.updateSapCardCode(this.inquiryID,$cardCode,cardName,priceList,currency,userNo).subscribe(data => {
          this.reloadInquiries();
       });
   } 
  

  }

  
  createQuotation(){
    this.send_status_quotation = "sending";
    this.matcoService.createQuotation(this.inquiryID).subscribe(data => {
      // console.log(data);
      this.reloadInquiries();
      this.send_status_quotation = "";
    }, error => {
      //  console.log(error)
      this.send_status_quotation = "";
       this.errorQuotationMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
    });
  }

  resendMail(){
    this.send_email_status = "sending";
    this.matcoService.reSendMail(this.inquiryID, this.userNo).subscribe(data => {
        this.reloadInquiries();
        this.send_email_status = "";
    },error => {
        // console.log(error)
        this.send_email_status = "";
        this.errorSendEmailMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
    });
  }

  reloadInquiries(){
    this.matcoService.getInquiriesByID(this.inquiryID).subscribe(data => {
      this.inquiries = data[0].inquiries; 
      this.details = this.inquiries[0].details; 
      this.sapCardCode = this.inquiries[0].sapCardCode;
      // console.log(this.inquiries);
      
   });
  }
}
