import { Component, OnInit } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-admin-view-order',
  templateUrl: './admin-view-order.component.html',
  styleUrls: ['./admin-view-order.component.css']
})
export class AdminViewOrderComponent implements OnInit {

  constructor(
    private matcoService: MatcoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  orderId;
  order;
  role;

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.role = JSON.parse(localStorage.getItem("user")).role;

    this.route.params
    .subscribe(
      (params: Params) => {
        this.orderId = params['id'];
        this.matcoService.viewOrder(this.orderId).subscribe(data => {

          if(data) {
            data[0].order[0].docTotal = parseFloat(data[0].order[0].docTotal).toFixed(2);
          this.order = data[0].order[0];
          // console.log(data[0].order)
          }
          
        })
      }
    )
  }

  download() {
    html2canvas(document.querySelector("#order")).then(canvas => {
    // console.log(canvas)
    var pdf = new jsPDF('p', 'pt', 'a4');
    pdf.internal.scaleFactor = 30;
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var imgData  = canvas.toDataURL("image/jpg", 5.0);
    pdf.addImage(canvas.toDataURL("image/png",1.0), 'JPEG',0,0,600,600);
    window.open(pdf)
    pdf.save('view-receipt.pdf');

});

}

}
