import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatcoService } from 'src/app/services/matco.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from 'src/app/model/country.model';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  @ViewChild('closeTermsModal') closeTermsModal: ElementRef;
  authenticated: boolean = false;
  userNo: string;
  form: FormGroup;
  remarks: FormControl;
  projectName: FormControl;
  countryDest: FormControl;
  terms: boolean;
  products:any;
  send_status = "";
  userEmail: string;
  fromDatabase : boolean = false;
  orderNo : string;
  docTotal : string;
  country: string;
  taxAmount: string;
  countries: Country[];
  discount;
  sapCardCode;
  orderErrorMsg = "";

  constructor( private matcoService: MatcoService,
               private fb: FormBuilder,
               private route: ActivatedRoute,
               private router: Router) { }

  ngOnInit() {
    this.matcoService.updateCartEmitter("addProduct");
    this.terms = false;
    // this.cartProducts = [];
    if(localStorage.getItem('user')){
      this.userNo = JSON.parse(localStorage.getItem('user')).userNo; 
      this.userEmail = JSON.parse(localStorage.getItem('user')).email; 
      this.country = JSON.parse(localStorage.getItem('user')).country;
      this.sapCardCode = JSON.parse(localStorage.getItem('user')).sapCardCode;
    }

    this.form = this.fb.group({
      secretProducts: this.fb.array([]),
      remarks: new FormControl(''),
      endUser: new FormControl('', Validators.required),
      endDestinationCountry: new FormControl(this.country, Validators.required),
      userNo: new FormControl(this.userNo),
      docTotal: new FormControl('')
    });

    // this.loadProductsDB();
    this.loadProducts();
    this.computeTotal("");

    this.matcoService.getAllCountries()
     .subscribe(
       (countries: Country[]) => {
         this.countries = countries;
       }
     );
  }

  get secretProducts(): FormArray{
    return this.form.get('secretProducts') as FormArray;
  }

  loadProductsDB() {
    this.matcoService.getUserOrderByEmail(this.userEmail).subscribe(data => {
      console.log(data[0].order[0])
      if(data[0].order.length > 0) {
        localStorage.setItem("secret", "true-"+data[0].order[0].docNum);
       

        for(let product of data[0].order[0].details) {
          // console.log(product.itemCode)

          if(product.itemCode.includes('S')) {

            this.matcoService.getSubitemDetails(product.itemCode,this.sapCardCode).subscribe(data => {
              var currentOrders = JSON.parse(localStorage.getItem("productsInOrder"));
              // console.log(data[0])
              // this.currency = data[0].currency;
              // this.avail = data[0].available;
              // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
              // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;

              currentOrders[product.itemCode] = product;
              currentOrders[product.itemCode].currency = data[0].currency;
              currentOrders[product.itemCode].available = data[0].available;
              // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
              // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;

              // console.log(currentOrders)
              localStorage.setItem("productsInOrder", JSON.stringify(currentOrders));
              this.matcoService.updateCartEmitter("addProduct");
            })

          } else {
            this.matcoService.getProductDetails(product.itemCode).subscribe(data =>{
              var currentOrders = JSON.parse(localStorage.getItem("productsInOrder"));
              // console.log(data[0])
              // this.currency = data[0].currency;
              // this.avail = data[0].available;
              // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
              // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;

              currentOrders[product.itemCode] = product;
              currentOrders[product.itemCode].currency = data[0].currency;
              currentOrders[product.itemCode].available = data[0].available;
              // currentOrders[product.itemCode].docNum = data[0].order[0].docNum;
              // currentOrders[product.itemCode].docTotal = data[0].order[0].docTotal;

              // console.log(currentOrders)
              localStorage.setItem("productsInOrder", JSON.stringify(currentOrders));
              this.matcoService.updateCartEmitter("addProduct");
            })
          }
          
          
        }
       
       
      } else {
        localStorage.setItem("secret", "false");
      }
      // this.router.navigate(['']);
    }) 
  }

  loadProducts(){

      this.products = JSON.parse(localStorage.getItem("productsInOrder"));

      for(var product in this.products){
        this.country = JSON.parse(localStorage.getItem('user')).country;
        // console.log(this.country)
        if(this.country == 'Singapore') 
        {
          this.products[product].quantity = Math.floor(parseFloat(this.products[product].quantity));
          this.products[product].price = (parseFloat(this.products[product].price).toFixed(2));
          var price = (parseFloat(this.products[product].price).toFixed(2));
          this.products[product].taxAmount = parseFloat((parseFloat(price) * parseFloat(this.products[product].quantity) * 0.07).toFixed(2));
          // console.log(this.products[product].taxAmount)
          this.products[product].lineTotal = (parseFloat(price) * parseFloat(this.products[product].quantity)).toFixed(2) ;
          this.products[product].lineTotal = (parseFloat(this.products[product].lineTotal) + parseFloat(this.products[product].taxAmount)).toFixed(2)
        } 
        else 
        {
          this.products[product].price = (parseFloat(this.products[product].price).toFixed(2));
          var price = (parseFloat(this.products[product].price).toFixed(2));
          this.products[product].taxAmount = 0;
          this.products[product].lineTotal = (parseFloat(this.products[product].price) * parseFloat(this.products[product].quantity)).toFixed(2);
        }
        
        this.secretProducts.push(this.fb.group(this.products[product]));

        if(this.products[product].docNum){
          this.orderNo = this.products[product].docNum;
          // console.log(this.orderNo)
        }
        
        this.docTotal = this.products[product].docTotal; 
        // console.log(this.products[product])
        
      }
      // console.log(this.orderNo);      
  }

  deleteProductInCart(property: string){

    delete this.products[property];
    localStorage.setItem("productsInOrder",JSON.stringify(this.products));
    this.products = JSON.parse(localStorage.getItem("productsInOrder"));
    
    let arr = [];

    for( var a in this.products) {
      // console.log(a);
      arr.push(this.products[a])
    }
  
    const body = {
      "secretProducts" : arr,
    }

     this.matcoService.addToCartDB(JSON.stringify(body), this.userNo);
    //  .subscribe(data => {
    //   console.log(data)
    // });
   // this.snackBarMsg = "Product removed from cart";
    this.matcoService.updateCartEmitter("deletecart");
    this.ngOnInit();
    
  }


  computeTotal(event, ) {
   
    var total = 0;
    this.form.value.secretProducts.forEach(function(product) {
      product.price = (parseFloat(product.price).toFixed(2));
      if(JSON.parse(localStorage.getItem('user')).country == 'Singapore') 
        {
          product.taxAmount = parseFloat((parseFloat(product.price) * parseFloat(product.quantity) * 0.07).toFixed(2));
        }
        else
        {
          product.taxAmount = 0;
        }
      product.lineTotal = ((parseFloat(product.price) * parseFloat(product.quantity)) + parseFloat(product.taxAmount)).toFixed(2);
      total += ((parseFloat(product.price) * parseFloat(product.quantity)) + parseFloat(product.taxAmount));
    })
    // this.discount = total;
    this.docTotal = '' + total;
    this.docTotal = (parseFloat(this.docTotal)).toString().split(".")[0];
    this.discount = (total - parseFloat(this.docTotal)) ;
    this.discount = parseFloat(this.discount).toFixed(2);
    // console.log(this.docTotal);
    // console.log(parseFloat(this.discount).toFixed(2));
  }

  disable() {
    this.terms = true;
    this.closeTermsModal.nativeElement.click();
  }

  goToTerms() {
    this.closeTermsModal.nativeElement.click();
    this.router.navigate(['/terms-and-condition']);
  }

  checkout() {
    var validated = "";

    if(this.form.value.secretProducts) {
      this.form.value.secretProducts.forEach(function(product) {
        if(product.quantity > product.available) {
          validated = "false";
          alert(product.itemName +": Quantity is above the number of the available stocks.");
        }
      });
    }
    

    if(validated == 'false' || this.form.value.endUser == '' || this.form.value.endDestinationCountry == '') {
      return;
    }

    if(this.userNo) {
      this.send_status = 'sending';
      var orders = JSON.stringify(this.form.value);
      // console.log(orders);

      this.fromDatabase = (localStorage.getItem('secret').split('-')[0] === 'true');
      // this.fromDatabase = (localStorage.getItem('secret') === 'true');
      // console.log(this.docTotal);
      // console.log(this.fromDatabase );

      if(this.fromDatabase) {
        this.orderNo = (localStorage.getItem('secret').split('-')[1]);
        this.matcoService.addToCartDB(orders, this.userNo);
        // .subscribe(data => {
        //   console.log(data)
        // });

        this.matcoService.updateOrder(orders, this.orderNo, this.docTotal, this.discount).subscribe(data => {
          this.send_status = '';
          // localStorage.setItem("productsInOrder", "{}");
          this.matcoService.updateCartEmitter("deletecart");
          this.ngOnInit();
          this.router.navigate(['/shipping-details']);
        }, error => {
          this.send_status = '';
          this.orderErrorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
        });
      } else {
        this.matcoService.addToCartDB(orders, this.userNo);
        // .subscribe(data => {
        //   console.log(data)
        // });
        this.matcoService.saveOrder(orders, this.docTotal, this.discount).subscribe(data => {
          this.send_status = '';
          // localStorage.setItem("productsInOrder", "{}");
          this.matcoService.updateCartEmitter("deletecart");
          this.ngOnInit();
          this.router.navigate(['/shipping-details']);
        }, error => {
          this.send_status = '';
          this.orderErrorMsg = "Please contact Matco Administrator (raffymagracia@matcoasia.com) to report this error. " + error.statusText + '('+ error.status+')';
        });
      }
      
    } else {
      alert("Please Login.");
    }
  }
}
